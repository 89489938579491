import React from 'react';
import ReactDOM from 'react-dom';
import Application from './application';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import TagManager from "react-gtm-module";

library.add(fas, fab);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
};

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<Application />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
