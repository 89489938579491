import React from 'react'
import _ from 'lodash'

interface Option {
  value: string,
  html: string,
}

interface LabelledSelectProps {
  label: string
  id: string
  options: Option[]
  selectedOption: string
  action: React.ChangeEventHandler<HTMLSelectElement>
}

const LabelledSelect: React.FC<LabelledSelectProps> = ({ label, id, options, selectedOption, action }) => {
  if (options.length === 0) {
    options.push({ value: '', html: '--' })
  }
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field is-narrow">
          <div className="select">
            <select id={id} onChange={action} defaultValue={selectedOption}>
              {(options.map((option) => (
                <option key={option.value} value={option.value}>{_.truncate(option.html)}</option>
              )))}
            </select>
          </div>
        </div>
      </div>
    </div>
    // <span>
    //   {label}
    //    style={{ marginLeft: '1em' }}
    // </span>
  )
}

export type { Option }
export { LabelledSelect }