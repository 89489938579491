// package: mondo.v1
// file: mondo/v1/service_availability_configuration.proto

var mondo_v1_service_availability_configuration_pb = require("../../mondo/v1/service_availability_configuration_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ServiceAvailabilityConfigurationService = (function () {
  function ServiceAvailabilityConfigurationService() {}
  ServiceAvailabilityConfigurationService.serviceName = "mondo.v1.ServiceAvailabilityConfigurationService";
  return ServiceAvailabilityConfigurationService;
}());

ServiceAvailabilityConfigurationService.CreateServiceAvailabilityConfiguration = {
  methodName: "CreateServiceAvailabilityConfiguration",
  service: ServiceAvailabilityConfigurationService,
  requestStream: false,
  responseStream: false,
  requestType: mondo_v1_service_availability_configuration_pb.CreateServiceAvailabilityConfigurationRequest,
  responseType: mondo_v1_service_availability_configuration_pb.CreateServiceAvailabilityConfigurationResponse
};

ServiceAvailabilityConfigurationService.UpdateServiceAvailabilityConfiguration = {
  methodName: "UpdateServiceAvailabilityConfiguration",
  service: ServiceAvailabilityConfigurationService,
  requestStream: false,
  responseStream: false,
  requestType: mondo_v1_service_availability_configuration_pb.UpdateServiceAvailabilityConfigurationRequest,
  responseType: mondo_v1_service_availability_configuration_pb.UpdateServiceAvailabilityConfigurationResponse
};

ServiceAvailabilityConfigurationService.GetServiceAvailabilityConfiguration = {
  methodName: "GetServiceAvailabilityConfiguration",
  service: ServiceAvailabilityConfigurationService,
  requestStream: false,
  responseStream: false,
  requestType: mondo_v1_service_availability_configuration_pb.GetServiceAvailabilityConfigurationRequest,
  responseType: mondo_v1_service_availability_configuration_pb.GetServiceAvailabilityConfigurationResponse
};

ServiceAvailabilityConfigurationService.FindServiceAvailabilityConfigurations = {
  methodName: "FindServiceAvailabilityConfigurations",
  service: ServiceAvailabilityConfigurationService,
  requestStream: false,
  responseStream: false,
  requestType: mondo_v1_service_availability_configuration_pb.FindServiceAvailabilityConfigurationsRequest,
  responseType: mondo_v1_service_availability_configuration_pb.FindServiceAvailabilityConfigurationsResponse
};

exports.ServiceAvailabilityConfigurationService = ServiceAvailabilityConfigurationService;

function ServiceAvailabilityConfigurationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ServiceAvailabilityConfigurationServiceClient.prototype.createServiceAvailabilityConfiguration = function createServiceAvailabilityConfiguration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ServiceAvailabilityConfigurationService.CreateServiceAvailabilityConfiguration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ServiceAvailabilityConfigurationServiceClient.prototype.updateServiceAvailabilityConfiguration = function updateServiceAvailabilityConfiguration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ServiceAvailabilityConfigurationService.UpdateServiceAvailabilityConfiguration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ServiceAvailabilityConfigurationServiceClient.prototype.getServiceAvailabilityConfiguration = function getServiceAvailabilityConfiguration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ServiceAvailabilityConfigurationService.GetServiceAvailabilityConfiguration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ServiceAvailabilityConfigurationServiceClient.prototype.findServiceAvailabilityConfigurations = function findServiceAvailabilityConfigurations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ServiceAvailabilityConfigurationService.FindServiceAvailabilityConfigurations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ServiceAvailabilityConfigurationServiceClient = ServiceAvailabilityConfigurationServiceClient;

