import React from 'react';

interface LabelledButtonProps {
  label: string;
  buttonText: string;
  buttonClasses: string[];
  action: React.MouseEventHandler<HTMLButtonElement>;
}
const LabelledButton: React.FC<LabelledButtonProps> = ({ label, buttonText, buttonClasses, action }) => {
  buttonClasses.unshift('button')
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <button className={buttonClasses.join(" ")} value={buttonText} onClick={action}>{buttonText}</button>
        </div>
      </div>
    </div>
  )
};

export { LabelledButton }