import { GOOGLE_OAUTH2 } from '../types/constants';

export const googleOAuth2 = (googleResponse: never[]) => {
    return async (dispatch: (arg0: { type: string; googleResponse: never[] }) => void) => {
        if (typeof googleResponse === 'undefined') {
            googleResponse = [];
        }

        localStorage.setItem('userInfo', JSON.stringify(googleResponse));

        dispatch({ type: GOOGLE_OAUTH2, googleResponse });
    };
};
