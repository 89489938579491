import React, { useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useTable, useFilters, useSortBy, Row } from 'react-table'
import parse from 'html-react-parser'

const Styles = styled.div`
  .vehicle-icon {
    height: 1.1em;
    vertical-align: middle;
  }
  
  .people-icon {
    height: 1.1em;
    padding: 0 0.3em;
    vertical-align: middle;
  }

  .sort-asc::after {
    content: "▲"
  }

  .sort-desc::after {
    content: "▼"
  }
`

interface ReportingTableProps {
  data: any
  filters: {
    filterHelperId: boolean
    filterSelectionDescription: boolean
    selectMarket: string
    selectHelperCount: string
  }
}

const ReportingBidSelectionTable: React.FC<ReportingTableProps> = ({ data, filters }) => {
  const renderHtml = useCallback((cell: any) => {
    return <div>{parse(cell.value)}</div>
  }, [])

  const columns = useMemo(() => {
    return [
      { Header: 'Accepted', accessor: 'dateTimeTextAccepted', Cell: renderHtml },
      { Header: 'Market', accessor: 'marketName' },
      { Header: 'Task#', accessor: 'jobNumberOrJobGroupNumberText', Cell: renderHtml },
      { Header: 'Helper#', accessor: 'helperId' },
      { Header: 'Helper', accessor: 'helperText' },
      { Header: 'Capabilities', accessor: 'capabilitiesText', Cell: renderHtml },
      { Header: 'Time Slot', accessor: 'dateTimeTextRange' },
      { Header: 'Selection Type', accessor: 'acceptedSelectionType' },
      { Header: 'Selection Description', accessor: 'acceptedSelectionTypeDescription' },
      { Header: '', accessor: 'jobOrGroupHelperCount' },
    ]
  }, [renderHtml])

  const hiddenColumns = useMemo(() => {
    return ['jobOrGroupHelperCount']
  }, [])
  const sortBy = useMemo(() => {
    return [{ id: 'dateTimeTextAccepted', desc: true }]
  }, [])

  const initialState = { hiddenColumns, sortBy }

  // useTable "as any" since typescript for react-table is out-of-whack right now
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleHideColumn,
    setAllFilters,
  } = useTable({ columns, data, initialState }, useFilters, useSortBy) as any

  const {
    filterHelperId,
    filterSelectionDescription,
    selectMarket,
    selectHelperCount,
  } = filters

  useEffect(() => {
    toggleHideColumn('acceptedSelectionTypeDescription', !filterSelectionDescription)
    toggleHideColumn('helperId', !filterHelperId)
  }, [data, toggleHideColumn, filterSelectionDescription, filterHelperId])

  useEffect(() => {
    const filters: { id: string, value: string }[] = []
    if (selectMarket) {
      filters.push({ id: 'marketName', value: selectMarket })
    }
    if (selectHelperCount) {
      filters.push({ id: 'jobOrGroupHelperCount', value: selectHelperCount })
    }
    setAllFilters(filters)
  }, [data, setAllFilters, selectMarket, selectHelperCount])

  return (
    <Styles>
      <div className="table-container">
        <table {...getTableProps()} className="table is-narrow is-hoverable is-bordered is-fullwidth tableWrap">
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row<object>) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Styles>
  )
}

export { ReportingBidSelectionTable }