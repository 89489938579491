import { APISERVER } from '../../types/constants'

interface DollyApiTokenGoogle {
  valid: boolean
  validateError: boolean
  googleId: string
  apitoken: string
}

const GetDollyApiTokenGoogle = async () => {
  const userInfo = localStorage.getItem('userInfo') || ''
  const googleResult = JSON.parse(userInfo) || {}
  const tokenId = googleResult.tokenId

  if (!tokenId) {

    return { valid: false, validateError: true, googleId: '', apitoken: '' }
  }

  const fetchPath = "/v2/utils/dollydashboard/authorize"
  const reqURL = APISERVER + fetchPath + `?idToken=${tokenId}`

  const res = await fetch(reqURL)
  const { googleId = '', apitoken = '' } = await res.json()
  const valid = (googleId && apitoken)
  return { valid, validateError: !valid, googleId, apitoken }
}

export type { DollyApiTokenGoogle }
export { GetDollyApiTokenGoogle }