import React from 'react';
import { Link } from 'react-router-dom';
import PanelBlock from '../../components/panel-block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Page = () => {
  return (
    <div>
      <section className="section pt-3 pb-0">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><Link to="/">Admin</Link></li>
              <li className="is-active"><Link to="/hr">People Ops</Link></li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="title">Resources</h2>

              <div className="box has-background-white-ter has-text-centered">
                <h2 className="title is-4">
                  <FontAwesomeIcon icon="book" />
                  <span className="pl-2">
                    Employee Handbook
                  </span>
                </h2>
                <p>
                  A guide for how to be a part of the Dolly team.
                </p>

                <a href="dolly-employee-handbook.pdf" className="button is-info mt-3">
                  View Employee Handbook
                </a>
              </div>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  For Employees
                </p>
                <PanelBlock title="bonusly" icon="award" url="https://bonus.ly/analytics/dashboard" />
                <PanelBlock title="Business Codes" icon="barcode" url="https://docs.google.com/document/d/1HgYc4dunYoIjcjbKZLsYOUQfE6duwOxquSK7sJXTpJo/edit?usp=sharing" />
                <PanelBlock title="Code of Business Ethics" icon="balance-scale" url="https://drive.google.com/file/d/1uKV2MOYYsnOR2f10HqoOh9hnS7i5t5gu" />
                <PanelBlock title="Confidential Information Sharing" icon="lock" url="https://docs.google.com/document/d/1YxUAtYx7x4mWtXIHh22pmFs3GGOq1CcGO7MKXKeKg_g/edit" />
                <PanelBlock title="Core Working Hours" icon="clock" url="https://docs.google.com/document/d/1Ekd9fLs8Sw4tQXXCDDLtXGbx2hFQYuyOA-OHbRk2rIw/edit#" />
                <PanelBlock title="Discount Code - Employee" icon="tag" url="https://docs.google.com/document/d/1ND3xmp41m48IbQ_NxxakDNb_Fw2Q_NKSmcrzNgbeD5I/edit?usp=sharing" />
                <PanelBlock title="Discount Code - Friends & Family" icon="tags" url="https://docs.google.com/document/d/1ND3xmp41m48IbQ_NxxakDNb_Fw2Q_NKSmcrzNgbeD5I/edit?usp=sharing" />
                <PanelBlock title="Employee Referral Policy" icon="user-friends" url="https://docs.google.com/document/d/13Om7cUFZRpdpc7BkdjB6EZhjSM61ZsQBU3R2Hq_jVa8/edit?usp=sharing" />
                <PanelBlock title="Expense Reports - How To File" icon="file-invoice-dollar" url="https://docs.google.com/document/d/1fy_RRJnAE-3M_ohjHTxinH7H7MY09NyvGJjC_HfVeEE" />
                <PanelBlock title="Google Account: 2-Step Verification" icon="google" iconPrefix="fab" url="https://docs.google.com/document/d/1ndG1EyoyfBzluvyfymrxDZbyHyw9Z21LHxbc2aEKBO8/edit" />
                <PanelBlock title="Google Calendar" icon="google" iconPrefix="fab" url="https://calendar.google.com/" />
                <PanelBlock title="Gusto" icon="hippo" url="https://gusto.com/" />
                <PanelBlock title="LastPass" icon="key" url="https://lastpass.com/" />
                <PanelBlock title="Perks at Work" icon="wind" url="https://www.perksatwork.com/" />
                <PanelBlock title="PTO/Holiday Policy" icon="suitcase" url="https://docs.google.com/document/d/1320jXw_Dol0V8cNiwTluAKpJ6nsHAoBgBopEfS7ikBU/edit" />
                <PanelBlock title="Working at Dolly HQ" icon="building" url="https://docs.google.com/document/d/1WDTuhbIrq6rbIdpFQCLcDZwasCpzI0d4LdywwtiJTGk" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Health Benefits
                </p>
                <PanelBlock title="Benefits Summaries" icon="file" url="https://drive.google.com/drive/folders/1Y5J-sNaUwmsjMfGNZLXKNJI8kT-DZ9FA" />
                <PanelBlock title="Full Benefit Details &amp; Enrollment forms" icon="file" url="https://drive.google.com/drive/folders/1zNNXZcDNcuvy9nQ7zRYfvXYQdSGhkv_K" />
              </nav>
            </div>
            <div className="column">
              <div className="box has-background-info has-text-white">
                <h3 className="title is-3 has-text-centered has-text-white">THE DOLLY CODE</h3>
                <div className="block">
                  <h3 className="title is-5 has-text-white">
                    DELIVER DELIGHT.
                  </h3>
                  We tirelessly strive to provide WOW experiences that balance the needs of our customers, Helpers and partners.
                </div>
                <div className="block">
                  <h3 className="title is-5 has-text-white">
                    ELEVATE OTHERS.
                  </h3>
                  We act with positive intent and can-do attitudes. We put others before ourselves. We are kind. Jerks are shown the door.
                </div>
                <div className="block">
                  <h3 className="title is-5 has-text-white">
                    SEEK DIFFERENCES.
                  </h3>
                  Our diversity makes us stronger. Be inclusive, encourage different perspectives, and foster an environment where anyone who celebrates our values can thrive.
                </div>
                <div className="block">
                  <h3 className="title is-5 has-text-white">
                    SHOW UP.
                  </h3>
                  We bring our "A" game every day. We work smart and get into the trenches to figure things out. We’re accountable and operate with a strong sense of integrity, transparency and urgency.
                  </div>
                <div className="block">
                  <h3 className="title is-5 has-text-white">
                    GET BETTER EVERY DAY.
                  </h3>
                  Every interaction is an opportunity to learn, improve and grow. We are open, adaptable and expect one another to share feedback, ideas and new information.
                </div>
                <div className="block">
                  <h3 className="title is-5 has-text-white">
                    EMBRACE THE “WHAT IF.”
                  </h3>
                  Reinvention requires brave, innovative risk-takers. We’re empowered to cultivate ideas and we experiment continuously. 
                </div>
                <div className="block">
                  <h3 className="title is-5 has-text-white">
                    IT’S ABOUT THE RESULTS.
                  </h3>
                  Our process is only as good as our results. We expect to win, and we celebrate together when we do.
                </div>
              </div>

              <div className="box">
                <h3 className="title is-5">Dolly Office Closures</h3>
                <div className="block content">
                  <ul>
                    <li>New Year's Day</li>
                    <li>Memorial Day</li>
                    <li>Juneteenth</li>
                    <li>4th of July</li>
                    <li>Labor Day</li>
                    <li>Presidential Election Day</li>
                    <li>Thanksgiving Day</li>
                    <li>Christmas Day</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page;
