import React from 'react';
import Routers from './routes';
import { Provider } from 'react-redux';
import store from './store';

function Application() {
  return (
    <Provider store={store}>
      <div className="App">
        <Routers />
      </div>
    </Provider>
  );
}

export default Application;
