import React, { useMemo } from 'react'//, { useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { ServiceAvailabilityConfiguration } from '../../../gen/mondo/v1/common_pb'

interface ConfigurationServiceAvailabilityConfigurationsListProps {
  data: ServiceAvailabilityConfiguration[]
  environment: string
}

const ConfigurationServiceAvailabilityConfigurationsList: React.FC<ConfigurationServiceAvailabilityConfigurationsListProps> = ({ data, environment }) => {

  const configurationItems = useMemo(() => {
    const baseURL = '/product/configurationServiceAvailability'
    const cols = [0, 1, 2]
    const rows = Math.floor(data.length / cols.length) + 1
    return cols.map((col) => {
      return (
        <div key={col} className='column'>
          {data.slice(col * rows, (col + 1) * rows).map((configuration) => {
            return (
              <p key={configuration.getId()}><Link to={`${baseURL}/${environment}/${configuration.getId()}`}>{configuration.getLabel()}</Link></p>
            )
          })}
          {col === 2 && (
            <p><Link to={`${baseURL}/${environment}/new`}>New Configuration</Link></p>
          )}
        </div>
      )
    })
  }, [data, environment])

  return (
    <div className="container is-max-desktop">
      <h1>Service Availability Configurations ({environment})</h1>
      <div className="columns is-centered">
        {configurationItems}
      </div>
    </div>
  )
}

export { ConfigurationServiceAvailabilityConfigurationsList }