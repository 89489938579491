import { v4 as uuidv4 } from 'uuid'
import { grpc } from '@improbable-eng/grpc-web'

const makeMetadata = (environment: string, token: string) => {
  return new grpc.Metadata({
    "request_id": uuidv4(),
    "env": environment,
    "token": token,
    "role": "admin",
  })
}

export { makeMetadata }