import _ from 'lodash'
import moment from 'moment-timezone'

import { fetchPost } from '../../../../actions/dollyApi/fetchPost'
import { DollyApiTokenGoogle } from '../../../../actions/dollyApi/get-dolly-api-token-google'


interface Bid {
  _id: string
  accepted_date: string
  accepted_selection_type?: string
  helper: string
  date_ranges?: {
    date: string
    ranges?: {
      start: string
      end: string
    }[]
  }[]
  job?: {
    _id: string
    job_number: string,
    market_name: string,
    details?: {
      helper_count: number,
    }
  }
  group?: {
    _id: string,
    jobgroup_number: string,
    helper_count: number,
    job_data: {
      job: {
        market_name: string
      }
    }[]
  }
}

const getDateTimeRangeTextFromBid = (bid: Bid) => {
  let segments: string[] = []
  for (const date_range of bid.date_ranges || []) {
    for (const range of date_range.ranges || []) {
      const { start, end } = range
      if (start === end) {
        const text: string = moment(start).format('YYYY-MM-DD hh:mm A')
        segments.push(text)
      } else {
        const text: string = moment(start).format('YYYY-MM-DD hh:mm') + '-' + moment(end).format('hh:mm A')
        segments.push(text)
      }
    }
  }
  return segments.join('<br>') || 'unknown'
}

const getMarketNameFromBid = (bid: Bid) => {
  let market = _.get(bid, 'job.market_name', false)
  if (!market) {
    const jobData = _.get(bid, 'group.job_data', [])
    for (const jobDatum of jobData) {
      market = _.get(jobDatum, 'job.market_name', false)
      if (market) break
    }
  }
  return market || 'unknown'
}

const getJobIdFromBid = (bid: Bid) => { return _.get(bid, 'job._id', false) }

const getJobGroupIdFromBid = (bid: Bid) => { return _.get(bid, 'group._id', false) }

const getJobNumberFromBid = (bid: Bid) => { return _.get(bid, 'job.job_number', false) }

const getJobGroupNumberFromBid = (bid: Bid) => { return _.get(bid, 'group.jobgroup_number', false) }

const getJobNumberOrJobGroupNumberTextFromBid = (bid: Bid) => {
  const jobNumber = getJobNumberFromBid(bid)
  if (jobNumber) return jobNumber
  const jobGroupNumber = getJobGroupNumberFromBid(bid)
  if (jobGroupNumber) return 'Group# ' + jobGroupNumber
  return 'Unknown'
}

const getHelperCountFromBid = (bid: Bid) => {
  let helperCount = _.get(bid, 'job.details.helper_count', false)
  if (!helperCount) {
    helperCount = _.get(bid, 'group.helper_count', false)
  }
  return (helperCount || 0).toString()
}

interface getReportActiveBidsQuery {
  dateTimeAcceptedStart: string
  dateTimeAcceptedEnd: string
}
const getReportActiveBids = async (dollyApiTokenGoogle: DollyApiTokenGoogle, query: getReportActiveBidsQuery) => {
  const { apitoken, googleId } = dollyApiTokenGoogle
  const body = JSON.stringify({
    ...query,
    accepted: 1,
    apitoken,
    googleId
  })
  const results = await fetchPost('/v2/bid/reportbids', body)
  return results as Bid[]
}


export type { Bid, getReportActiveBidsQuery }
export {
  getReportActiveBids,
  getJobIdFromBid,
  getJobGroupIdFromBid,
  getMarketNameFromBid,
  getJobNumberOrJobGroupNumberTextFromBid,
  getDateTimeRangeTextFromBid,
  getHelperCountFromBid,
}