export const GOOGLE_OAUTH2 = 'GOOGLE_OAUTH2'
export const GTM_ID = process.env.REACT_APP_GTM_ID
export const APISERVER = process.env.REACT_APP_APISERVER || 'https://api-support.dolly.com'
export const DOLLY_PLATFORM_URL = process.env.REACT_APP_DOLLY_PLATFORM_URL || 'https://platform.dolly.com:443'
export const TEAM_ICON = '&#128101;'
export const SINGLE_HELPER_ICON = '&#128100;'
export const HANDS_ICON = '&#128080;'
export const PICKUP_TRUCK_ICON = '<img class="vehicle-icon" src="https://dolly-images.s3-us-west-2.amazonaws.com/pickup_truck.png" title="pickup_truck" />'
export const CARGO_VAN_ICON = '<img class="vehicle-icon" src="https://dolly-images.s3-us-west-2.amazonaws.com/van.png" title="cargo_van" />'
export const BOX_TRUCK_ICON = '<img class="vehicle-icon" src="https://dolly-images.s3-us-west-2.amazonaws.com/box_truck.png" title="box_truck" />'
export const REPORTING_BID_SELECTION_EXPLAINERS = {
  "gimmeprimary": "Helper, selected for primary, bid flagged with \"gimme_the_job\"",
  "gimmesecondary": "Helper, selected for secondary, bid flagged with \"gimme_the_job\"",
  "conditionalhelper": "\"Pair me with\" Helpers/Hands selected together",
  "handshelper": "Team (2 Helper Dollys) / Helper (1 Helper Dollys) selected",
  "helper": "Helper (Solo) selected",
  "hand": "Hands selected",
  "helperhand": "Helper selected for secondary (Dolly does not require vehicle)",
  "splithelper": "Helper selected for fifty/fifty",
  "groupConditionalHelper": "Route: \"Pair me with\" Helpers/Hands selected together",
  "groupRandom": "Route: Helper/Hands selected",
  "assignHelper": "Support: Helper/Hands assigned",
  "forceAccept": "Helper/Hands selected, bid flagged with \"force_accept\"",
  "voucherRedeemed": "Helper/Hands selected, voucher (Power-Up) redeemed"
}
export const REPORTING_BID_SELECTION_UNKNOWN = "Unknown bid selection reason"