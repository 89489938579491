// source: mondo/v1/service_availability_configuration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var mondo_v1_common_pb = require('../../mondo/v1/common_pb.js');
goog.object.extend(proto, mondo_v1_common_pb);
goog.exportSymbol('proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest', null, global);
goog.exportSymbol('proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse', null, global);
goog.exportSymbol('proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest', null, global);
goog.exportSymbol('proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse', null, global);
goog.exportSymbol('proto.mondo.v1.GetServiceAvailabilityConfigurationRequest', null, global);
goog.exportSymbol('proto.mondo.v1.GetServiceAvailabilityConfigurationResponse', null, global);
goog.exportSymbol('proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest', null, global);
goog.exportSymbol('proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.displayName = 'proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.displayName = 'proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.displayName = 'proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.displayName = 'proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.GetServiceAvailabilityConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.displayName = 'proto.mondo.v1.GetServiceAvailabilityConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.GetServiceAvailabilityConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.displayName = 'proto.mondo.v1.GetServiceAvailabilityConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.displayName = 'proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.repeatedFields_, null);
};
goog.inherits(proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.displayName = 'proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scope: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    overrideScope: jspb.Message.getFieldWithDefault(msg, 10, ""),
    weaverCustomerEntityId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    weaverLaborEntityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    outsideServiceAreaDeliverableRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    longRangeDeliveryLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    taskMarketSurgeLimitsMap: (f = msg.getTaskMarketSurgeLimitsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest;
  return proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverrideScope(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeaverCustomerEntityId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeaverLaborEntityId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOutsideServiceAreaDeliverableRange(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongRangeDeliveryLimit(value);
      break;
    case 9:
      var value = msg.getTaskMarketSurgeLimitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOverrideScope();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWeaverCustomerEntityId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWeaverLaborEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOutsideServiceAreaDeliverableRange();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLongRangeDeliveryLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTaskMarketSurgeLimitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
};


/**
 * optional string scope = 2;
 * @return {string}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string override_scope = 10;
 * @return {string}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getOverrideScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setOverrideScope = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string weaver_customer_entity_id = 5;
 * @return {string}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getWeaverCustomerEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setWeaverCustomerEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string weaver_labor_entity_id = 6;
 * @return {string}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getWeaverLaborEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setWeaverLaborEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double outside_service_area_deliverable_range = 7;
 * @return {number}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getOutsideServiceAreaDeliverableRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setOutsideServiceAreaDeliverableRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double long_range_delivery_limit = 8;
 * @return {number}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getLongRangeDeliveryLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.setLongRangeDeliveryLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * map<string, int64> task_market_surge_limits = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.getTaskMarketSurgeLimitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationRequest.prototype.clearTaskMarketSurgeLimitsMap = function() {
  this.getTaskMarketSurgeLimitsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse;
  return proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse} returns this
 */
proto.mondo.v1.CreateServiceAvailabilityConfigurationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    overrideScope: jspb.Message.getFieldWithDefault(msg, 10, ""),
    weaverCustomerEntityId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    weaverLaborEntityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    outsideServiceAreaDeliverableRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    longRangeDeliveryLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    taskMarketSurgeLimitsMap: (f = msg.getTaskMarketSurgeLimitsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest;
  return proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverrideScope(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeaverCustomerEntityId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeaverLaborEntityId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOutsideServiceAreaDeliverableRange(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongRangeDeliveryLimit(value);
      break;
    case 9:
      var value = msg.getTaskMarketSurgeLimitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOverrideScope();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWeaverCustomerEntityId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWeaverLaborEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOutsideServiceAreaDeliverableRange();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLongRangeDeliveryLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTaskMarketSurgeLimitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scope = 2;
 * @return {string}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string override_scope = 10;
 * @return {string}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getOverrideScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setOverrideScope = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string weaver_customer_entity_id = 5;
 * @return {string}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getWeaverCustomerEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setWeaverCustomerEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string weaver_labor_entity_id = 6;
 * @return {string}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getWeaverLaborEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setWeaverLaborEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double outside_service_area_deliverable_range = 7;
 * @return {number}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getOutsideServiceAreaDeliverableRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setOutsideServiceAreaDeliverableRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double long_range_delivery_limit = 8;
 * @return {number}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getLongRangeDeliveryLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.setLongRangeDeliveryLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * map<string, int64> task_market_surge_limits = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.getTaskMarketSurgeLimitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationRequest.prototype.clearTaskMarketSurgeLimitsMap = function() {
  this.getTaskMarketSurgeLimitsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse;
  return proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.UpdateServiceAvailabilityConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.GetServiceAvailabilityConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationRequest}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.GetServiceAvailabilityConfigurationRequest;
  return proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.GetServiceAvailabilityConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationRequest}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.GetServiceAvailabilityConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scope = 2;
 * @return {string}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationRequest} returns this
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.GetServiceAvailabilityConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    configuration: (f = msg.getConfiguration()) && mondo_v1_common_pb.ServiceAvailabilityConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationResponse}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.GetServiceAvailabilityConfigurationResponse;
  return proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.GetServiceAvailabilityConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationResponse}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mondo_v1_common_pb.ServiceAvailabilityConfiguration;
      reader.readMessage(value,mondo_v1_common_pb.ServiceAvailabilityConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.GetServiceAvailabilityConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mondo_v1_common_pb.ServiceAvailabilityConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServiceAvailabilityConfiguration configuration = 1;
 * @return {?proto.mondo.v1.ServiceAvailabilityConfiguration}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.prototype.getConfiguration = function() {
  return /** @type{?proto.mondo.v1.ServiceAvailabilityConfiguration} */ (
    jspb.Message.getWrapperField(this, mondo_v1_common_pb.ServiceAvailabilityConfiguration, 1));
};


/**
 * @param {?proto.mondo.v1.ServiceAvailabilityConfiguration|undefined} value
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationResponse} returns this
*/
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.GetServiceAvailabilityConfigurationResponse} returns this
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.GetServiceAvailabilityConfigurationResponse.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    retrieveInactive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest;
  return proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRetrieveInactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRetrieveInactive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool retrieve_inactive = 1;
 * @return {boolean}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.prototype.getRetrieveInactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest} returns this
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsRequest.prototype.setRetrieveInactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    configurationsList: jspb.Message.toObjectList(msg.getConfigurationsList(),
    mondo_v1_common_pb.ServiceAvailabilityConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse;
  return proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mondo_v1_common_pb.ServiceAvailabilityConfiguration;
      reader.readMessage(value,mondo_v1_common_pb.ServiceAvailabilityConfiguration.deserializeBinaryFromReader);
      msg.addConfigurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      mondo_v1_common_pb.ServiceAvailabilityConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServiceAvailabilityConfiguration configurations = 1;
 * @return {!Array<!proto.mondo.v1.ServiceAvailabilityConfiguration>}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.prototype.getConfigurationsList = function() {
  return /** @type{!Array<!proto.mondo.v1.ServiceAvailabilityConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, mondo_v1_common_pb.ServiceAvailabilityConfiguration, 1));
};


/**
 * @param {!Array<!proto.mondo.v1.ServiceAvailabilityConfiguration>} value
 * @return {!proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse} returns this
*/
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.prototype.setConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mondo.v1.ServiceAvailabilityConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration}
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.prototype.addConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mondo.v1.ServiceAvailabilityConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse} returns this
 */
proto.mondo.v1.FindServiceAvailabilityConfigurationsResponse.prototype.clearConfigurationsList = function() {
  return this.setConfigurationsList([]);
};


goog.object.extend(exports, proto.mondo.v1);
