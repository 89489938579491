import React from 'react';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import GoogleLogoutLink from '../google-logout-link';
import { useHistory } from 'react-router-dom';

const GlobalNavbar = (props: any) => {
  const history = useHistory();
  const [isActive, setisActive] = React.useState(false);

  if (typeof (JSON.parse(localStorage.getItem('userInfo') || '{}')).accessToken === 'undefined') history.replace('/login');

  return (
    <nav className="navbar has-shadow has-background-white-ter" role="navigation" aria-label="main navigation">
      <div className="navbar-brand has-background-grey-lighter">
        <Link className="navbar-item is-family-monospace is-size-6 has-text-primary" to="/">
          <img
              src={process.env.PUBLIC_URL + '/dolly-color-logo.svg'}
              alt="Dolly Admin"
              style={{marginBottom: '-4px'}}
              />
            admin
        </Link>

        <Link
          to="/"
          role="button" className={'navbar-burger burger ' + ((isActive) ? 'is-active' : '')}
          aria-label="menu"
          aria-expanded="false"
          onClick={() => {
            setisActive(!isActive); }
          }
          >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </Link>
      </div>

      <div className={'navbar-menu pl-4 ' + ((isActive) ? 'is-active' : '')}>
        <div className="navbar-start">
          <Link className={'navbar-item is-tab' + (useRouteMatch({path: '/HR'}) ? ' is-active' : '')} to="/hr">People Ops</Link>
          <Link className={'navbar-item is-tab' + (useRouteMatch({path: '/support'}) ? ' is-active' : '')} to="/support">Support</Link>
          <Link className={'navbar-item is-tab' + (useRouteMatch({path: '/helper'}) ? ' is-active' : '')} to="/helper">Helper</Link>
          <Link className={'navbar-item is-tab' + (useRouteMatch({path: '/sales'}) ? ' is-active' : '')} to="/sales">Sales</Link>
          <Link className={'navbar-item is-tab' + (useRouteMatch({path: '/marketing'}) ? ' is-active' : '')} to="/marketing">Marketing</Link>
          <Link className={'navbar-item is-tab' + (useRouteMatch({path: '/product'}) ? ' is-active' : '')} to="/product">Product & Engineering</Link>
        </div>

        <div className="navbar-end">
        <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/">Quick Links</Link>

            <div className="navbar-dropdown is-right">
              <a className="navbar-item" href="https://bd-dashboard.dolly.com">
                <FontAwesomeIcon icon="building" />
                <span className="pl-2">
                  Business/Site Manager
                </span>
              </a>
              <a className="navbar-item" href="https://lighthouse.dolly.com">
                <FontAwesomeIcon icon="chess-rook" />
                <span className="pl-2">
                  Lighthouse
                </span>
              </a>
              <a className="navbar-item" href="https://metabase.dolly.com/">
                <FontAwesomeIcon icon="database" />
                <span className="pl-2">
                  Metabase
                </span>
              </a>
              <a className="navbar-item" href="https://dolly.myjetbrains.com/youtrack/dashboard">
                <FontAwesomeIcon icon="ticket-alt" />
                <span className="pl-2">
                  Youtrack
                </span>
              </a>
              <a className="navbar-item" href="https://dolly.zendesk.com/agent/dashboard">
                <FontAwesomeIcon icon="headset" />
                <span className="pl-2">
                  Zendesk
                </span>
              </a>
            </div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/">
              <FontAwesomeIcon icon={faUser} />
              <span className="pl-2">
                { (props.googleReducer.profileObj && props.googleReducer.profileObj.name) }
              </span>
            </Link>

            <div className="navbar-dropdown is-right">
              <GoogleLogoutLink />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

function mapStateToProps (state: any) {
  return {
      ...state,
  };
}

export default connect(mapStateToProps)(GlobalNavbar);

