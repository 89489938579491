// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import JobLookup from '../../components/job-lookup';

const Home = () => (
  <section className="section">
    <div className="container">
      <h2 className="title">Dolly Admin</h2>

      <div className="columns">
        <div className="column">
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-title has-background-white-ter has-text-centered py-3">
                  <h3 className="title is-4">People Ops</h3>
                </div>
                <div className="card-content has-text-right">
                  <Link className="button is-fullwidth" to="/hr">
                    View More
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-title has-background-white-ter has-text-centered py-3">
                  <h3 className="title is-4">Support</h3>
                </div>
                <div className="card-content">
                  <Link className="button is-fullwidth" to="/support">
                    View More
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-title has-background-white-ter has-text-centered py-3">
                  <h3 className="title is-4">Helper</h3>
                </div>
                <div className="card-content">
                  <Link className="button is-fullwidth" to="/helper">
                    View More
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-title has-background-white-ter has-text-centered py-3">
                  <h3 className="title is-4">Sales</h3>
                </div>
                <div className="card-content has-text-right">
                  <Link className="button is-fullwidth" to="/sales">
                    View More
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-title has-background-white-ter has-text-centered py-3">
                  <h3 className="title is-4">Marketing</h3>
                </div>
                <div className="card-content">
                  <Link className="button is-fullwidth" to="/marketing">
                    View More
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-title has-background-white-ter has-text-centered py-3">
                  <h3 className="title is-4">Product & Engineering</h3>
                </div>
                <div className="card-content">
                  <Link className="button is-fullwidth" to="/product">
                    View More
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*
          <div className="column is-one-third">
            <JobLookup />
          </div>
        */}
      </div>
    </div>
  </section>
);

export default Home;
