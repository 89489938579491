import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { googleOAuth2 } from '../actions/google-auth';
import { useHistory } from 'react-router-dom';

const clientId = '466642712127-ngnrn0skcoi8s0f7la0acfdlso4vu9im.apps.googleusercontent.com';

const GoogleLoginButton = (props: { googleReducer: any; googleOAuth2: ((response: GoogleLoginResponse | GoogleLoginResponseOffline) => void) | undefined }) => {
  const history = useHistory();

  useEffect(() => {
    if (typeof (JSON.parse(localStorage.getItem('userInfo') || '{}')).accessToken !== 'undefined') history.replace('/');
  });

  return (
    <GoogleLogin
      clientId={clientId}
      render={renderProps => (
        <button className="button is-primary is-large" onClick={renderProps.onClick} disabled={renderProps.disabled}>SIGN IN</button>
      )}
      buttonText="Login"
      onSuccess={props.googleOAuth2}
      onFailure={props.googleOAuth2}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
      hostedDomain="taskrabbit.com"
    />
  );
};

function mapStateToProps (state: any) {
  return {
      ...state,
  };
}

function mapDispatchToProps (dispatch: Dispatch<AnyAction>) {
  return bindActionCreators ({ googleOAuth2 }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLoginButton);
