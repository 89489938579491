import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { googleOAuth2 } from '../../../actions/google-auth'
import { DollyApiTokenGoogle, GetDollyApiTokenGoogle } from '../../../actions/dollyApi/get-dolly-api-token-google'
import { fetchPost } from '../../../actions/dollyApi/fetchPost'
import { getMarkets } from '../../../actions/dollyApi/getMarkets'
import { findHelpersCurated } from '../../../actions/dollyApi/findHelpersCurated'

import { LabelledButton } from '../../../components/labelled-button'
import { LabelledSelect, Option } from '../../../components/labelled-select'

import { convertReportingFulfillmentDataToTableData } from './reportingFulfillment/convertReportingFulfillmentDataToTableData'
import { convertReportingFulfillmentDataToMissingBroadcastTableData } from './reportingFulfillment/convertReportingFulfillmentDataToMissingBroadcastTableData'
import ReportingFulfillmentTable from './reportingFulfillmentTable'
import ReportingFulfillmentTableMissingBroadcast from './reportingFulfillmentTableMissingBroadcast'
import styles from './reportingFulfillment.module.css'


const getBusinessOptions = async (dollyApiTokenGoogle: DollyApiTokenGoogle) => {
  const { apitoken, googleId } = dollyApiTokenGoogle
  const body = JSON.stringify({
    apitoken,
    googleId,
    fields: ["name"]
  })

  const businesses = await fetchPost('/v2/business/find', body)

  const defaultOption = { value: '', html: '--' }
  if (!Array.isArray(businesses)) {
    return [defaultOption]
  }

  const businessOptions: Option[] = []
  for (const business of businesses) {
    const { _id: value = '', name: html = '' } = business
    businessOptions.push({ value, html })
  }
  businessOptions.sort((a, b) => { return a.html > b.html ? 1 : -1 })
  businessOptions.unshift(defaultOption)
  return businessOptions
}

interface getJobsAndBidWindowCountsQuery {
  status: string[]
  startDateTime: string
  endDateTime: string
}
const getJobsAndBidWindowCounts = async (dollyApiTokenGoogle: DollyApiTokenGoogle, query: getJobsAndBidWindowCountsQuery) => {
  const { apitoken, googleId } = dollyApiTokenGoogle
  const body = JSON.stringify({
    ...query,
    apitoken,
    googleId
  })
  const results = await fetchPost('/v2/job/reporting/fulfillment/getjobsandbidwindowcounts', body)
  return results
}

const getHelpersMarketCategories = async (dollyApiTokenGoogle: DollyApiTokenGoogle) => {
  const { apitoken, googleId } = dollyApiTokenGoogle
  const body = JSON.stringify({
    apitoken,
    googleId
  })
  const results = await fetchPost('/v2/job/reporting/fulfillment/gethelpersmarketcategories', body)
  return results
}

const Page = (
  props: {
    googleReducer: any;
    googleOAuth2: ((response: never[]) => void)
  }
) => {
  const { googleReducer, googleOAuth2 } = props

  const history = useHistory()
  const [dollyApiTokenGoogle, setDollyApiTokenGoogle] = useState<DollyApiTokenGoogle>({ valid: false, validateError: false, googleId: '', apitoken: '' })

  const [dataLoadedOnce, setDataLoadedOnce] = useState<boolean>(false)
  const [dataNeedsRefresh, setDataNeedsRefresh] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)

  const [statusOpen, setStatusOpen] = useState<boolean>(true)
  const [statusCancelled, setStatusCancelled] = useState<boolean>(false)
  const [statusScheduled, setStatusScheduled] = useState<boolean>(false)
  const [statusComplete, setStatusComplete] = useState<boolean>(false)
  const [filterPastBroadcasts, setFilterPastBroadcasts] = useState<boolean>(false)

  const [selectDate, setSelectDate] = useState<string>('today')
  const [marketOptions, setMarketOptions] = useState<Option[]>([{ value: '', html: 'Loading...' }])
  const [selectMarket, setSelectMarket] = useState<string>('')
  const [businessOptions, setBusinessOptions] = useState<Option[]>([{ value: '', html: 'Loading...' }])
  const [selectBusiness, setSelectBusiness] = useState<string>('')

  const [marketConnections, setMarketConnections] = useState<any>({})
  // TODO: type def mega objects
  const [fulfillmentData, setFulfillmentData] = useState<any>([])

  const [fulfillmentTableMissingBroadcastModel, setFulfillmentTableMissingBroadcastModel] = useState<boolean>(false)
  const [missingBroadcastJobId, setMissingBroadcastJobId] = useState<string>('')

  const marketsToMarketOptions = (markets: any) => {
    const defaultOption = { value: '', html: '--' }
    if (typeof markets !== 'object' || markets === null) {
      return [defaultOption]
    }

    const marketOptions: Option[] = []
    // Just using market keys
    for (const market in markets) {
      marketOptions.push({ value: market, html: market })
    }
    marketOptions.sort((a, b) => { return a.html > b.html ? 1 : -1 })
    marketOptions.unshift(defaultOption)
    return marketOptions
  }

  const marketsToMarketConnections = (markets: any) => {
    const marketConnections = _.mapValues(markets, (market) => {
      const connections = _.isArray(market.market_connections) ? market.market_connections : []
      connections.push(market.name)
      return _.uniq(connections)
    })
    return marketConnections
  }

  const makeGetJobsAndBidWindowCountsQuery = () => {
    const status: string[] = []
    if (statusOpen) { status.push('open'); status.push('pending') }
    if (statusCancelled) { status.push('cancelled') }
    if (statusScheduled) { status.push('scheduled') }
    if (statusComplete) { status.push('tipreview'); status.push('complete') }
    if (status.length === 0) { status.push('open'); status.push('pending') }

    let startDateTime = moment().startOf('day')
    let endDateTime = moment().endOf('day')
    switch (selectDate) {
      case "-1days":
        startDateTime = startDateTime.subtract(1, 'day')
        endDateTime = endDateTime.subtract(1, 'day')
        break;
      case "+1days":
        startDateTime = startDateTime.add(1, 'day')
        endDateTime = endDateTime.add(1, 'day')
        break;
      case "+2days":
        startDateTime = startDateTime.add(2, 'day')
        endDateTime = endDateTime.add(2, 'day')
        break;
      case "today..+3days":
        endDateTime = endDateTime.add(3, 'day')
        break;
      case "today..+7days":
        endDateTime = endDateTime.add(7, 'day')
        break;
      case "today":
      default:
        break;
    }

    const getJobsAndBidWindowCountsQuery = {
      status,
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
    }
    return getJobsAndBidWindowCountsQuery
  }

  const refreshFulfillmentData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // sanity check with seattle
    if (typeof marketConnections['seattle'] === undefined) {
      console.log('marketConnections missing')
      return
    }

    setDataLoadedOnce(true)
    setDataNeedsRefresh(false)
    setDataLoading(true)

    const getJobsAndBidWindowCountsQuery = makeGetJobsAndBidWindowCountsQuery()

    Promise.all([
      getJobsAndBidWindowCounts(dollyApiTokenGoogle, getJobsAndBidWindowCountsQuery),
      getHelpersMarketCategories(dollyApiTokenGoogle),
      findHelpersCurated(dollyApiTokenGoogle, { retrieveContactInfo: true }),
    ]).then(([
      getJobsAndBidWindowCountsResults,
      getHelpersMarketCategoriesResults,
      findHelpersCuratedResults
    ]) => {
      const { jobs, jobCounts } = getJobsAndBidWindowCountsResults
      const { helperIdsByMarket, helperCategoryCounts } = getHelpersMarketCategoriesResults
      const { helpersById } = findHelpersCuratedResults
      setFulfillmentData({
        jobs,
        jobCounts,
        helperIdsByMarket,
        helperCategoryCounts,
        helpersById,
      })
      setDataLoading(false)
    })
  }

  const fulfillmentTableData = useMemo(() => {
    if (_.isEmpty(marketConnections) || _.isEmpty(fulfillmentData.jobs)) {
      return []
    }
    return convertReportingFulfillmentDataToTableData(fulfillmentData, marketConnections)
  }, [marketConnections, fulfillmentData])

  const fulfillmentTableMissingBroadcastData = useMemo(() => {
    if (_.isEmpty(marketConnections) || _.isEmpty(fulfillmentData.jobs) || _.isEmpty(missingBroadcastJobId)) {
      return []
    }
    return convertReportingFulfillmentDataToMissingBroadcastTableData(fulfillmentData, marketConnections, missingBroadcastJobId)
  }, [marketConnections, fulfillmentData, missingBroadcastJobId])

  useEffect(() => {
    GetDollyApiTokenGoogle().then((result) => {
      const { valid, validateError } = result
      if (!valid || validateError) {
        googleOAuth2([])
        return
      }
      setDollyApiTokenGoogle(result)
    })
  }, [googleOAuth2, history])

  useEffect(() => {
    if (googleReducer === []) {
      history.replace('/login')
    }
  }, [googleReducer, history])

  useEffect(() => {
    if (!dollyApiTokenGoogle.valid) {
      return
    }
    getMarkets(dollyApiTokenGoogle).then((markets) => {
      setMarketOptions(marketsToMarketOptions(markets))
      setMarketConnections(marketsToMarketConnections(markets))
    })
    getBusinessOptions(dollyApiTokenGoogle).then(setBusinessOptions)
  }, [dollyApiTokenGoogle])

  useEffect(() => {
    setDataNeedsRefresh(true)
  }, [selectDate, statusOpen, statusCancelled, statusScheduled, statusComplete])

  const loadMissingBroadcastModal = useCallback((jobId) => {
    setMissingBroadcastJobId(jobId)
    setFulfillmentTableMissingBroadcastModel(true)
  }, [setMissingBroadcastJobId, setFulfillmentTableMissingBroadcastModel])

  return (
    <div className={["content", styles.containerTop].join(' ')}>
      <div>
        <div className={styles.containerHoriz}>
          <h1 style={{ margin: "0 1em 0 0" }} className="title">Fulfillment Reporting</h1>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Date:</label>
            </div>
            <div className="field-body is-narrow">
              <div className="select" style={{ marginRight: "1em" }}>
                <select id="fulfillment-table-date-select" defaultValue="today"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setSelectDate(e.currentTarget.value) }}>
                  <option value="-1days">yesterday</option>
                  <option value="today">today</option>
                  <option value="+1days">tomorrow</option>
                  <option value="+2days">day after tomorrow</option>
                  <option value="today..+3days">today + next 3 days</option>
                  <option value="today..+7days">today + next 7 days</option>
                </select>
              </div>
            </div>
          </div>
          {
            (dataLoading &&
              <div id="fulfillment-table-loading-text" style={{ float: "right", fontWeight: "bold" }}>
                Loading...
                </div>)
            || (!dataLoadedOnce &&
              <LabelledButton label="Initial&nbsp;load:" buttonText="Load Data" buttonClasses={["is-info"]} action={refreshFulfillmentData} />)
            || (dataNeedsRefresh &&
              <LabelledButton label="Refresh&nbsp;required:" buttonText="Refresh Data" buttonClasses={["is-info"]} action={refreshFulfillmentData} />
            )
            || (<LabelledButton label="" buttonText="Reload" buttonClasses={["is-info"]} action={refreshFulfillmentData} />)
          }
        </div>
        <h4 style={{ margin: "5px" }} className="subtitle">Visibility into broadcast views for Helpers &amp; Hands.</h4>
        <div className={styles.containerHoriz} style={{ justifyContent: "space-around", padding: "10px 0" }}>
          <label className="checkbox">
            <input type="checkbox" id="status-open" value="open" defaultChecked={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStatusOpen(e.currentTarget.checked) }} />
            &nbsp;Open
          </label>
          <label className="checkbox">
            <input type="checkbox" id="status-cancelled" value="cancelled"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStatusCancelled(e.currentTarget.checked) }} />
            &nbsp;Cancelled
          </label>
          <label className="checkbox">
            <input type="checkbox" id="status-scheduled" value="scheduled"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStatusScheduled(e.currentTarget.checked) }} />
            &nbsp;Scheduled
          </label>
          <label className="checkbox">
            <input type="checkbox" id="status-completed" value="completed"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStatusComplete(e.currentTarget.checked) }} />
            &nbsp;Completed
          </label>
          <span>|</span>
          <label className="checkbox">
            <input type="checkbox" id="show-broadcasted" value="1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setFilterPastBroadcasts(e.currentTarget.checked) }} />
            &nbsp;Past Broadcasts
          </label>
          <span>&nbsp;</span>
          <LabelledSelect
            label="Market:"
            id="fulfillment-table-market-select"
            options={marketOptions}
            selectedOption={selectMarket}
            action={(e: React.ChangeEvent<HTMLSelectElement>) => { setSelectMarket(e.currentTarget.value) }}
          />
          <LabelledSelect
            label="Business:"
            id="fulfillment-table-business-select"
            options={businessOptions}
            selectedOption={selectBusiness}
            action={(e: React.ChangeEvent<HTMLSelectElement>) => { setSelectBusiness(e.currentTarget.value) }}
          />
        </div>
      </div>
      <div id="fulfillment-table"></div>
      <ReportingFulfillmentTable
        data={fulfillmentTableData}
        filters={{ filterPastBroadcasts, selectMarket, selectBusiness }}
        loadMissingBroadcastModal={loadMissingBroadcastModal}
      />
      <div className={["modal", (fulfillmentTableMissingBroadcastModel ? "is-active" : "")].join(" ")}>
        <div className="modal-background"></div>
        <div className={["modal-content", styles.fulfillmentJobMissingHelpersModalContent].join(" ")}>
          <h3 className="subtitle">Helpers Missing Bid Window or Visibility:</h3>
          <ReportingFulfillmentTableMissingBroadcast
            data={fulfillmentTableMissingBroadcastData}
          />
        </div>
        <button className="modal-close is-large" aria-label="close"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { setFulfillmentTableMissingBroadcastModel(false) }}
        ></button>
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    ...state,
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ googleOAuth2 }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
