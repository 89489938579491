import React from 'react';

const GlobalFooter = () => {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong>&copy;2022 Dolly</strong>
          <span className="px-2">
            ::
          </span>
          <a href="https://dolly.com">
            dolly.com
          </a>
          <span className="px-2">
            ::
          </span>

          <a href="www.dolly.com">
            <img
                src={process.env.PUBLIC_URL + '/dolly-color-logo.svg'}
                alt="Dolly Admin"
                style={{width: '70px', marginBottom: '-20px'}}
                />
          </a>
        </p>
      </div>
    </footer>
  );
};

export default GlobalFooter;

