import _ from 'lodash'

import {
  TEAM_ICON,
  SINGLE_HELPER_ICON,
  HANDS_ICON,
  PICKUP_TRUCK_ICON,
  CARGO_VAN_ICON,
  BOX_TRUCK_ICON,
} from '../../types/constants'

const getVehicleIcon = (vehicleType: string) => {
  switch (vehicleType) {
    case 'pickup_truck':
      return PICKUP_TRUCK_ICON
    case 'cargo_van':
      return CARGO_VAN_ICON
    case 'box_truck':
      return BOX_TRUCK_ICON
    case 'hands':
    default:
      return HANDS_ICON
  }
}

const getPersonIcon = (helperCount: Number, personCount: Number) => {
  let image = 'single-helper'
  if (helperCount.toString() === '2') {
    image = 'two-helpers'
  } else if (personCount.toString() === '2') {
    image = 'me-and-helper'
  }
  const src = 'https://dolly.com/images/' + image + '.png'
  return '<img class="people-icon" src="' + src + '" title="' + image + '">'
}

const rolePersonIcon = {
  team: TEAM_ICON,
  primary: SINGLE_HELPER_ICON,
  secondary: HANDS_ICON,
}
const getRoleIcon = (helper: any) => {
  const helperRole = getHelperRole(helper)
  return rolePersonIcon[helperRole] || HANDS_ICON
}

const getJobMarket = (job: any) => { return _.get(job, 'market_name', 'no_market') }
const getJobVehicle = (job: any) => { return _.get(job, 'vehicle_type', 'hands') }

const getHelperMarket = (helper: any) => { return _.get(helper, 'roles.helper.market_name', 'no_market') }
const getHelperTier = (helper: any) => { return _.get(helper, 'roles.helper.tier', 'no_tier') }
// helper vehicles are presorted by endpoint
const getHelperVehicle = (helper: any) => { return _.get(helper, 'roles.helper.vehicles[0].type', 'hands') }
const getHelperRole = (helper: any) => {
  const helperVehicle = getHelperVehicle(helper)
  const isHelperTeam = _.get(helper, 'roles.helper.helper_count', '1').toString() === '2'
  return helperVehicle !== 'hands' ? (isHelperTeam ? 'team' : 'primary') : 'secondary'
}
const getHelperName = (helper: any) => { return _.get(helper, 'last_name', 'Last') + ', ' + _.get(helper, 'first_name', 'First') }
const getHelperEmail = (helper: any) => { return _.get(helper, 'email', 'No Email') }
const getHelperNumber = (helper: any) => { return _.get(helper, 'roles.helper.helper_number', 'Helper Number not found') }
const getHelperCapabilitiesIcons = (helper: any) => {
  const helperVehicle = getHelperVehicle(helper)
  return getRoleIcon(helper) + (helperVehicle !== 'hands' ? getVehicleIcon(helperVehicle) : '')
}

export {
  getVehicleIcon,
  getPersonIcon,
  getRoleIcon,
  getJobMarket,
  getJobVehicle,
  getHelperMarket,
  getHelperTier,
  getHelperVehicle,
  getHelperRole,
  getHelperName,
  getHelperEmail,
  getHelperNumber,
  getHelperCapabilitiesIcons,
}