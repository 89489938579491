import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Map } from 'google-protobuf'
import { DollyApiTokenGoogle } from '../../../actions/dollyApi/get-dolly-api-token-google'
import { makeMetadata } from '../../../actions/mondoService/makeMetadata'
import { saveServiceAvailabilityConfiguration } from '../../../actions/mondoService/saveServiceAvailabilityConfiguration'
import { ServiceAvailabilityConfiguration } from '../../../gen/mondo/v1/common_pb'

interface ConfigurationServiceAvailabilityEditFormProps {
  configuration: ServiceAvailabilityConfiguration
  environment: string
  dollyApiTokenGoogle: DollyApiTokenGoogle
  retrieveConfiguration: React.Dispatch<string>
}

const ConfigurationServiceAvailabilityEditForm: React.FC<ConfigurationServiceAvailabilityEditFormProps> = ({
  configuration: conf,
  environment,
  dollyApiTokenGoogle,
  retrieveConfiguration
}) => {
  const baseUrl = `/product/configurationServiceAvailability/${environment}`

  const history = useHistory()

  const [changed, setChanged] = useState(false);
  const [invalidSubmission, setInvalidSubmission] = useState("");
  const [disableSave, setDisableSave] = useState(false)
  const [saveError, setSaveError] = useState("")
  const isNewConfiguration = conf.getId() === 'new'
  const [scope, setScope] = useState(conf.getScope());
  const [label, setLabel] = useState(conf.getLabel());
  const [active, setActive] = useState(conf.getActive());
  const [overrideScope, setOverrideScope] = useState(conf.getOverrideScope());
  const [weaverCustomerEntityId, setWeaverCustomerEntityId] = useState(conf.getWeaverCustomerEntityId());
  const [weaverLaborEntityId, setWeaverLaborEntityId] = useState(conf.getWeaverLaborEntityId());
  const [outsideServiceAreaDeliverableRange, setOutsideServiceAreaDeliverableRange] = useState(conf.getOutsideServiceAreaDeliverableRange());
  const [longRangeDeliveryLimit, setLongRangeDeliveryLimit] = useState(conf.getLongRangeDeliveryLimit());
  const [taskMarketSurgeLimitsMap, setTaskMarketSurgeLimitsMap] = useState(conf.getTaskMarketSurgeLimitsMap());

  useEffect(() => {
    setDisableSave(!changed || !!invalidSubmission)
  }, [changed, invalidSubmission, setDisableSave])

  const convertMapToJson = (map: Map<string, number>) => {
    let arrayMap = map.toArray()
    let objectMap = arrayMap.reduce<any>((obj, d) => Object.assign(obj, { [d[0]]: d[1] }), {})
    return JSON.stringify(objectMap, null, '  ')
  }

  const updateTaskMarketSurgeLimitsJson = (json: string) => {
    setInvalidSubmission('Checking TaskMarketSurgeLimits...')
    try {
      const parsedObject = JSON.parse(json)
      const parsedObjectKeys = Object.keys(parsedObject)
      const validDow = ['M', 'T', 'W', 'R', 'F', 'S', 'Y']
      for (const key of parsedObjectKeys) {
        const split = key.split('^')
        if (split.length < 1 || split.length > 2 || split[0] === '' || (typeof split[1] !== 'undefined' && !validDow.includes(split[1]))) {
          throw (Error(`TaskMarketSurgeLimits key "${key}" is invalid`))
        }
      }
      const parsedArray = parsedObjectKeys.reduce<[string, number][]>((arr, key) => { arr.push([key, +parsedObject[key]]); return arr }, [])
      const newMap = new Map<string, number>(parsedArray)
      setTaskMarketSurgeLimitsMap(newMap)
      setInvalidSubmission('')
      setChanged(true)
    } catch (error) {
      setInvalidSubmission(error.toString())
    }
  }

  const saveConfiguration = () => {
    setDisableSave(true)
    setSaveError("")
    const configuration = new ServiceAvailabilityConfiguration()
    configuration.setId(conf.getId())
    configuration.setScope(scope)
    configuration.setLabel(label)
    configuration.setActive(active)
    configuration.setOverrideScope(overrideScope)
    configuration.setWeaverCustomerEntityId(weaverCustomerEntityId)
    configuration.setWeaverLaborEntityId(weaverLaborEntityId)
    configuration.setOutsideServiceAreaDeliverableRange(outsideServiceAreaDeliverableRange)
    configuration.setLongRangeDeliveryLimit(longRangeDeliveryLimit)
    taskMarketSurgeLimitsMap.toArray().forEach(d => configuration.getTaskMarketSurgeLimitsMap().set(d[0], d[1]))

    let metadata = makeMetadata(environment, dollyApiTokenGoogle.apitoken)
    saveServiceAvailabilityConfiguration(metadata, configuration)
      .then((id) => {
        history.replace(`/product/configurationServiceAvailability/${environment}/${id}`)
        retrieveConfiguration(id)
      })
      .catch((error) => {
        setSaveError(error.toString())
        setDisableSave(false)
      })
  }

  return (
    <div className="container is-max-desktop">
      <h1>Service Availability Configuration Edit ({environment})</h1>
      <div className="field">
        <label className="label">Id</label>
        <div className="control">
          <p>{isNewConfiguration ? 'New Configuration' : conf.getId()}</p>
        </div>
      </div>
      <div className="field">
        <label className="label">Scope</label>
        <div className="control">
          <input className="input" id="scope" type="text"
            defaultValue={conf.getScope()}
            disabled={!isNewConfiguration}
            onChange={e => { setScope(e.target.value); setChanged(true) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Label</label>
        <div className="control">
          <input className="input" id="label" type="text"
            defaultValue={conf.getLabel()}
            onChange={e => { setLabel(e.target.value); setChanged(true) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Active</label>
        <div className="control">
          <label className="checkbox">
            <input type="checkbox" id="active"
              defaultChecked={conf.getActive()}
              disabled={isNewConfiguration}
              onChange={e => { setActive(e.target.checked); setChanged(true) }}
            />
          </label>
        </div>
      </div>
      <div className="field">
        <label className="label">Override Scope</label>
        <div className="control">
          <input className="input" id="overrideScope" type="text"
            defaultValue={conf.getOverrideScope()}
            onChange={e => { setOverrideScope(e.target.value); setChanged(true) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Weaver Customer Entity Id</label>
        <div className="control">
          <input className="input" id="weaverCustomerEntityId" type="text"
            defaultValue={conf.getWeaverCustomerEntityId()}
            onChange={e => { setWeaverCustomerEntityId(e.target.value); setChanged(true) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Weaver Labor Entity Id</label>
        <div className="control">
          <input className="input" id="weaverLaborEntityId" type="text"
            defaultValue={conf.getWeaverLaborEntityId()}
            onChange={e => { setWeaverLaborEntityId(e.target.value); setChanged(true) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Outside Service Area Deliverable Range</label>
        <div className="control">
          <input className="input" id="outsideServiceAreaDeliverableRange" type="text"
            defaultValue={conf.getOutsideServiceAreaDeliverableRange()}
            onChange={e => { setOutsideServiceAreaDeliverableRange(+e.target.value); setChanged(true) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Long Range Delivery Limit</label>
        <div className="control">
          <input className="input" id="longRangeDeliveryLimit" type="text"
            defaultValue={conf.getLongRangeDeliveryLimit()}
            onChange={e => { setLongRangeDeliveryLimit(+e.target.value); setChanged(true) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Task Market Surge Limits</label>
        <div className="control">
          <textarea className="textarea" id="taskMarketSurgeLimitsJson" rows={8}
            defaultValue={convertMapToJson(conf.getTaskMarketSurgeLimitsMap())}
            onChange={e => updateTaskMarketSurgeLimitsJson(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link" onClick={saveConfiguration} disabled={disableSave}>
              {isNewConfiguration ? "Create" : "Update"}
            </button>
          </div>
          <div className="control">
            <Link to={baseUrl}>
              <button className="button is-link is-light">Return to list</button>
            </Link>
          </div>
        </div>
      </div>
      <p>{invalidSubmission}</p>
      <p>{saveError}</p>
      <hr />
      <h2>Documentation</h2>
      <p>This service, "Service Availability", provides availability determinations for Dolly and partners by provided addresses and dates.</p>
      <p>The fields are as follows</p>
      <ul>
        <li>
          <p>
            <strong>Scope</strong> - (eg. "Dolly", "Lighthouse")
            The scope is provided by the client doing the service availability request.
            This will generally be one of a few pre-determined values.
          </p>
        </li>
        <li><p><strong>Label</strong> - descriptive label for this configuration</p></li>
        <li><p><strong>Active</strong> - If set not active, the configuration for that scope will revert to default values.</p></li>
        <li><p><strong>Override Scope</strong> - "Dolly" is set at this time to use Location and Input Service behaviors defined for Dolly</p></li>
        <li><p><strong>Weaver Customer Entity Id</strong> - Set to "e01182ba-521e-459c-bbb3-3f185cd4ff34" to use the Dolly Customer pool configuration of the Scheduling Service</p></li>
        <li><p><strong>Weaver Labor Entity Id</strong> - Set to "f7f849c2-a49c-4c20-a551-7d5f38eee6c0" to use the Dolly Helper pool configuration of the Scheduling Service</p></li>
        <li><p><strong>Outside Service Area Deliverable Range</strong> - Default of 50 miles. The maximum range (as crow flies) outside of market boundaries allowed for a starting Dolly location.</p></li>
        <li><p><strong>Long Range Delivery Limit</strong> - Default of 50 miles. The maximum driving distance, outside market boundaries, of a Dolly allowed.</p></li>
        <li>
          <p><strong>Task Market Surge Limits</strong> - Per market, maximum number of daily Dollys desired. Accepts json key-value pairs.</p>
          <p>Can be configured with either:</p>
          <ul>
            <li>Market/Daily Limit key-value pair (eg. "seattle": 400)</li>
            <li>Market^DOW/DOW Limit key-value pair (eg. "seattle^M": 300), using one letter DOW abbreviation (M, T, W, R, F, S, Y)</li>
          </ul>
          <p>If Market^DOW/DOW Limit pair is not provided, configuration will fall back to Market/Daily Limit pair. If a market surge limit value cannot be determined for a date's DOW, there will be no surge limit for that market and date.</p>
          <p><a href="/sampleJson/configurationServiceAvailabilitySampleTaskMarketSurgeLimits.json" target="_blank" rel="noopener noreferrer">Example json</a></p>
        </li>
      </ul>
    </div>
  )
}

export { ConfigurationServiceAvailabilityEditForm }