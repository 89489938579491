import _ from 'lodash'
import moment from 'moment-timezone'

import {
  REPORTING_BID_SELECTION_EXPLAINERS,
  REPORTING_BID_SELECTION_UNKNOWN
} from '../../../../types/constants'

import {
  getHelperName,
  getHelperEmail,
  getHelperNumber,
  getHelperCapabilitiesIcons,
} from '../../../../actions/dollyApi/dollyDataFunctions'

import { CuratedHelpersById, CURATED_HELPER_UNKNOWN } from '../../../../actions/dollyApi/findHelpersCurated'

import {
  Bid,
  getJobIdFromBid,
  getJobGroupIdFromBid,
  getMarketNameFromBid,
  getJobNumberOrJobGroupNumberTextFromBid,
  getDateTimeRangeTextFromBid,
  getHelperCountFromBid,
} from './getReportActiveBids'


interface ReportingData {
  bids: Bid[]
  helpersById: CuratedHelpersById
}

function convertReportingDataToTableData(reportingFulfillmentData: ReportingData, marketConnections: string[]) {
  const {
    bids,
    helpersById,
  } = reportingFulfillmentData

  const tableData = []
  for (const bid of bids) {
    const {
      _id: bidId,
      accepted_date: acceptedDate,
      accepted_selection_type: acceptedSelectionType = 'unknown',
      helper: helperId,
    } = bid
    if (!bidId) {
      continue
    }

    const marketName = getMarketNameFromBid(bid)
    const jobId = getJobIdFromBid(bid)
    const jobGroupId = getJobGroupIdFromBid(bid)
    const jobNumberOrJobGroupNumberText = getJobNumberOrJobGroupNumberTextFromBid(bid)
    const jobOrGroupHelperCount = getHelperCountFromBid(bid)

    const dateTimeTextAccepted = `<!--${moment(acceptedDate).format()}-->` + moment(acceptedDate).format('YYYY-MM-DD hh:mmA')
    const dateTimeTextRange = getDateTimeRangeTextFromBid(bid)

    const helper = _.get(helpersById, helperId, CURATED_HELPER_UNKNOWN)
    const helperName = getHelperName(helper)
    const helperEmail = getHelperEmail(helper)
    const helperText = `${helperName} (${helperEmail})`
    const helperNumber = getHelperNumber(helper)
    const capabilitiesText = getHelperCapabilitiesIcons(helper)

    const acceptedSelectionTypeDescription = _.get(REPORTING_BID_SELECTION_EXPLAINERS, acceptedSelectionType, REPORTING_BID_SELECTION_UNKNOWN)

    const datum = {
      bidId,
      jobId,
      jobGroupId,
      jobNumberOrJobGroupNumberText,
      jobOrGroupHelperCount,
      marketName,
      dateTimeTextAccepted,
      dateTimeTextRange,
      acceptedSelectionType,
      acceptedSelectionTypeDescription,
      helperId,
      helperText,
      helperNumber,
      capabilitiesText,
    }
    tableData.push(datum)
  }
  return tableData
}

export type { ReportingData }
export { convertReportingDataToTableData }