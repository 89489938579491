import React from 'react';
import { Link } from 'react-router-dom';
import PanelBlock from '../../components/panel-block';

const Page = () => {
  return (
    <div>
      <section className="section pt-3 pb-0">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><Link to="/">Admin</Link></li>
              <li className="is-active"><Link to="/marketing">Marketing</Link></li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Manage
                </p>
                <PanelBlock title="Asana" icon="check-square" url="https://app.asana.com/" />
                <PanelBlock title="Best Photos" icon="images" url="https://drive.google.com/drive/u/1/folders/1cF9Q_iWg63wwgURrPSc8a_JdSXEg5Fxc" />
                <PanelBlock title="Content Calendar" icon="calendar" url="https://airtable.com/apprFK8DVqf1KvSr6/tblo6g5I99WJqahvA/viwkGA8rVuUbWjLPz?blocks=hide" />
                <PanelBlock title="Facebook" icon="facebook" iconPrefix="fab" url="https://www.facebook.com/dolly/" />
                <PanelBlock title="Instagram" icon="instagram" iconPrefix="fab" url="https://www.instagram.com/getdolly" />
                <PanelBlock title="LinkedIn" icon="linkedin" iconPrefix="fab" url="https://www.linkedin.com/company/3995103" />
                <PanelBlock title="Mailchimp" icon="mailchimp" iconPrefix="fab" url="https://login.mailchimp.com/" />
                <PanelBlock title="Outfunnel" icon="headset" url="https://app.outfunnel.com/login" />
                <PanelBlock title="Pinterest" icon="pinterest" iconPrefix="fab" url="https://www.pinterest.com/dollytruckandmuscle" />
                <PanelBlock title="Sprout Social" icon="seedling" url="https://app.sproutsocial.com/login" />
                <PanelBlock title="Twitter" icon="twitter" iconPrefix="fab" url="https://twitter.com/getdolly" />
                <PanelBlock title="Vimeo" icon="vimeo" iconPrefix="fab" url="https://vimeo.com/user128274524" />
                <PanelBlock title="Yelp" icon="yelp" iconPrefix="fab" url="https://biz.yelp.com/login?return_url=%2F" />
              </nav>
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Paid
                </p>
                <PanelBlock title="Apple Search Ads" icon="apple" iconPrefix="fab" url="https://idmsa.apple.com/IDMSWebAuth/signin?appIdKey=a01459d797984726ee0914a7097e53fad42b70e1f08d09294d14523a1d4f61e1&rv=1&path=" />
                <PanelBlock title="Google AdWords" icon="google" iconPrefix="fab" url="https://ads.google.com/aw/overview?authuser=0&__u=8380606938&__c=9055076791&pli=1" />
                <PanelBlock title="Facebook Business Manager" icon="facebook" iconPrefix="fab" url="https://business.facebook.com/home/accounts?business_id=871203786285483" />
                <PanelBlock title="LinkedIn Ads" icon="linkedin" iconPrefix="fab" url="https://www.linkedin.com/campaignmanager/accounts" />
              </nav>
            </div>
            <div className="column">
            <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Create
                </p>
                <PanelBlock title="Adobe Creative Cloud" icon="file-image" url="https://www.adobe.com/#ref_cc" />
                <PanelBlock title="Brand Guidelines" icon="book" url="https://company-28024.frontify.com/d/baB39PE0Q8rh/dolly-brand-guidelines" />
                <PanelBlock title="Canva" icon="file-powerpoint" url="https://www.canva.com/" />
                <PanelBlock title="Discount Code Creator" icon="comment-dollar" url="https://dolly.com/jobmap/discounts.html" />
                <PanelBlock title="Dolly Blog" icon="blog" url="https://dolly.com/blog/wp-login" />
                <PanelBlock title="Gimp" icon="magic" url="https://www.gimp.org/" />
                <PanelBlock title="Survey Monkey" icon="poll-h" url="https://www.surveymonkey.com/user/sign-in/?ut_source=homepage&ut_source3=megamenu" />
              </nav>
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Research
                </p>
                <PanelBlock title="Ahrefs" icon="search-plus" url="https://ahrefs.com/user/login" />
                <PanelBlock title="Full Story" icon="ruler" url="https://app.fullstory.com" />
                <PanelBlock title="Google Analytics" icon="google" iconPrefix="fab" url="https://analytics.google.com/analytics/web/#/home/a47314819w87453659p90800730/" />
              </nav>
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Assets
                </p>
                <PanelBlock title="Logo (for canvas, dst)" icon="tshirt" url="/fabric-dolly-logo.DST" />
                <PanelBlock title="Logo (for canvas, pdf)" icon="tshirt" url="/fabric-dolly-logo.pdf" />
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page;
