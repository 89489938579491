// @ts-nocheck
import React from 'react';
import GoogleLoginButton from '../../components/google-login-button';

const Login = () => (
  <div className="columns is-vcentered">
    <div className="column is-two-fifths">
      <div className="container has-text-centered">
        <h1 className="title is-4 mb-3">Dolly Admin</h1>
        <div className="mb-6">Sign in using your Dolly account</div>
        <GoogleLoginButton />
      </div>
    </div>
    <div className="column is-three-fifths has-background-grey-darker">
      <div className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered has-text-white">
            <h3 className="title is-2 has-text-white">Welcome to Dolly Admin</h3>
            <p className="subtitle has-text-white">
              Your one-stop spot for all things Dolly.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Login;
