// source: mondo/v1/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mondo.v1.AddressComponents', null, global);
goog.exportSymbol('proto.mondo.v1.Coordinate', null, global);
goog.exportSymbol('proto.mondo.v1.DateAvailability', null, global);
goog.exportSymbol('proto.mondo.v1.DirectionsRoute', null, global);
goog.exportSymbol('proto.mondo.v1.DirectionsRoute.DistanceUnits', null, global);
goog.exportSymbol('proto.mondo.v1.DirectionsRoute.Features', null, global);
goog.exportSymbol('proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary', null, global);
goog.exportSymbol('proto.mondo.v1.DirectionsRoute.Leg', null, global);
goog.exportSymbol('proto.mondo.v1.Index', null, global);
goog.exportSymbol('proto.mondo.v1.Location', null, global);
goog.exportSymbol('proto.mondo.v1.Location.Attributes', null, global);
goog.exportSymbol('proto.mondo.v1.Location.Attributes.MapSourceInfo', null, global);
goog.exportSymbol('proto.mondo.v1.Market', null, global);
goog.exportSymbol('proto.mondo.v1.ServiceAvailabilityComplianceReason', null, global);
goog.exportSymbol('proto.mondo.v1.ServiceAvailabilityConfiguration', null, global);
goog.exportSymbol('proto.mondo.v1.ServiceAvailabilityOptions', null, global);
goog.exportSymbol('proto.mondo.v1.ServiceAvailabilityReason', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.ServiceAvailabilityConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.ServiceAvailabilityConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.ServiceAvailabilityConfiguration.displayName = 'proto.mondo.v1.ServiceAvailabilityConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.ServiceAvailabilityOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.ServiceAvailabilityOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.ServiceAvailabilityOptions.displayName = 'proto.mondo.v1.ServiceAvailabilityOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.Index = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.Index, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.Index.displayName = 'proto.mondo.v1.Index';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.DateAvailability = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.DateAvailability, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.DateAvailability.displayName = 'proto.mondo.v1.DateAvailability';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.Location.displayName = 'proto.mondo.v1.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.Location.Attributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.Location.Attributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.Location.Attributes.displayName = 'proto.mondo.v1.Location.Attributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mondo.v1.Location.Attributes.MapSourceInfo.repeatedFields_, null);
};
goog.inherits(proto.mondo.v1.Location.Attributes.MapSourceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.Location.Attributes.MapSourceInfo.displayName = 'proto.mondo.v1.Location.Attributes.MapSourceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.AddressComponents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.AddressComponents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.AddressComponents.displayName = 'proto.mondo.v1.AddressComponents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.Coordinate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.Coordinate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.Coordinate.displayName = 'proto.mondo.v1.Coordinate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.Market = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mondo.v1.Market.repeatedFields_, null);
};
goog.inherits(proto.mondo.v1.Market, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.Market.displayName = 'proto.mondo.v1.Market';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.DirectionsRoute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mondo.v1.DirectionsRoute.repeatedFields_, null);
};
goog.inherits(proto.mondo.v1.DirectionsRoute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.DirectionsRoute.displayName = 'proto.mondo.v1.DirectionsRoute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.DirectionsRoute.Leg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.DirectionsRoute.Leg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.DirectionsRoute.Leg.displayName = 'proto.mondo.v1.DirectionsRoute.Leg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.DirectionsRoute.Features = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.DirectionsRoute.Features, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.DirectionsRoute.Features.displayName = 'proto.mondo.v1.DirectionsRoute.Features';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.displayName = 'proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.ServiceAvailabilityConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.ServiceAvailabilityConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    overrideScope: jspb.Message.getFieldWithDefault(msg, 10, ""),
    weaverCustomerEntityId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    weaverLaborEntityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    outsideServiceAreaDeliverableRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    longRangeDeliveryLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    taskMarketSurgeLimitsMap: (f = msg.getTaskMarketSurgeLimitsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.ServiceAvailabilityConfiguration;
  return proto.mondo.v1.ServiceAvailabilityConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.ServiceAvailabilityConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverrideScope(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeaverCustomerEntityId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeaverLaborEntityId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOutsideServiceAreaDeliverableRange(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongRangeDeliveryLimit(value);
      break;
    case 9:
      var value = msg.getTaskMarketSurgeLimitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.ServiceAvailabilityConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.ServiceAvailabilityConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOverrideScope();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWeaverCustomerEntityId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWeaverLaborEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOutsideServiceAreaDeliverableRange();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLongRangeDeliveryLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTaskMarketSurgeLimitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scope = 2;
 * @return {string}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string override_scope = 10;
 * @return {string}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getOverrideScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setOverrideScope = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string weaver_customer_entity_id = 5;
 * @return {string}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getWeaverCustomerEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setWeaverCustomerEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string weaver_labor_entity_id = 6;
 * @return {string}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getWeaverLaborEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setWeaverLaborEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double outside_service_area_deliverable_range = 7;
 * @return {number}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getOutsideServiceAreaDeliverableRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setOutsideServiceAreaDeliverableRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double long_range_delivery_limit = 8;
 * @return {number}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getLongRangeDeliveryLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.setLongRangeDeliveryLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * map<string, int64> task_market_surge_limits = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.getTaskMarketSurgeLimitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mondo.v1.ServiceAvailabilityConfiguration} returns this
 */
proto.mondo.v1.ServiceAvailabilityConfiguration.prototype.clearTaskMarketSurgeLimitsMap = function() {
  this.getTaskMarketSurgeLimitsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.ServiceAvailabilityOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.ServiceAvailabilityOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.ServiceAvailabilityOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkAmbiguousAddress: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    primaryLocationIndex: (f = msg.getPrimaryLocationIndex()) && proto.mondo.v1.Index.toObject(includeInstance, f),
    primaryMarket: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deliveryBuffer: jspb.Message.getFieldWithDefault(msg, 4, 0),
    schedulingConstraintsMap: (f = msg.getSchedulingConstraintsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions}
 */
proto.mondo.v1.ServiceAvailabilityOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.ServiceAvailabilityOptions;
  return proto.mondo.v1.ServiceAvailabilityOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.ServiceAvailabilityOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions}
 */
proto.mondo.v1.ServiceAvailabilityOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckAmbiguousAddress(value);
      break;
    case 2:
      var value = new proto.mondo.v1.Index;
      reader.readMessage(value,proto.mondo.v1.Index.deserializeBinaryFromReader);
      msg.setPrimaryLocationIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMarket(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeliveryBuffer(value);
      break;
    case 5:
      var value = msg.getSchedulingConstraintsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.ServiceAvailabilityOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.ServiceAvailabilityOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.ServiceAvailabilityOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckAmbiguousAddress();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPrimaryLocationIndex();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mondo.v1.Index.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryMarket();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeliveryBuffer();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSchedulingConstraintsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional bool check_ambiguous_address = 1;
 * @return {boolean}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.getCheckAmbiguousAddress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions} returns this
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.setCheckAmbiguousAddress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Index primary_location_index = 2;
 * @return {?proto.mondo.v1.Index}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.getPrimaryLocationIndex = function() {
  return /** @type{?proto.mondo.v1.Index} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.Index, 2));
};


/**
 * @param {?proto.mondo.v1.Index|undefined} value
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions} returns this
*/
proto.mondo.v1.ServiceAvailabilityOptions.prototype.setPrimaryLocationIndex = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions} returns this
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.clearPrimaryLocationIndex = function() {
  return this.setPrimaryLocationIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.hasPrimaryLocationIndex = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string primary_market = 3;
 * @return {string}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.getPrimaryMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions} returns this
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.setPrimaryMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 delivery_buffer = 4;
 * @return {number}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.getDeliveryBuffer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions} returns this
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.setDeliveryBuffer = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * map<string, string> scheduling_constraints = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.getSchedulingConstraintsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mondo.v1.ServiceAvailabilityOptions} returns this
 */
proto.mondo.v1.ServiceAvailabilityOptions.prototype.clearSchedulingConstraintsMap = function() {
  this.getSchedulingConstraintsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.Index.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.Index.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.Index} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Index.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.Index}
 */
proto.mondo.v1.Index.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.Index;
  return proto.mondo.v1.Index.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.Index} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.Index}
 */
proto.mondo.v1.Index.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.Index.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.Index.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.Index} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Index.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 index = 1;
 * @return {number}
 */
proto.mondo.v1.Index.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.Index} returns this
 */
proto.mondo.v1.Index.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.DateAvailability.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.DateAvailability.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.DateAvailability} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DateAvailability.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    available: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    reason: jspb.Message.getFieldWithDefault(msg, 3, 0),
    complianceReason: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.DateAvailability}
 */
proto.mondo.v1.DateAvailability.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.DateAvailability;
  return proto.mondo.v1.DateAvailability.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.DateAvailability} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.DateAvailability}
 */
proto.mondo.v1.DateAvailability.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvailable(value);
      break;
    case 3:
      var value = /** @type {!proto.mondo.v1.ServiceAvailabilityReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 4:
      var value = /** @type {!proto.mondo.v1.ServiceAvailabilityComplianceReason} */ (reader.readEnum());
      msg.setComplianceReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.DateAvailability.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.DateAvailability.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.DateAvailability} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DateAvailability.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvailable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getComplianceReason();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.mondo.v1.DateAvailability.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.DateAvailability} returns this
 */
proto.mondo.v1.DateAvailability.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool available = 2;
 * @return {boolean}
 */
proto.mondo.v1.DateAvailability.prototype.getAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.DateAvailability} returns this
 */
proto.mondo.v1.DateAvailability.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional ServiceAvailabilityReason reason = 3;
 * @return {!proto.mondo.v1.ServiceAvailabilityReason}
 */
proto.mondo.v1.DateAvailability.prototype.getReason = function() {
  return /** @type {!proto.mondo.v1.ServiceAvailabilityReason} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mondo.v1.ServiceAvailabilityReason} value
 * @return {!proto.mondo.v1.DateAvailability} returns this
 */
proto.mondo.v1.DateAvailability.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ServiceAvailabilityComplianceReason compliance_reason = 4;
 * @return {!proto.mondo.v1.ServiceAvailabilityComplianceReason}
 */
proto.mondo.v1.DateAvailability.prototype.getComplianceReason = function() {
  return /** @type {!proto.mondo.v1.ServiceAvailabilityComplianceReason} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.mondo.v1.ServiceAvailabilityComplianceReason} value
 * @return {!proto.mondo.v1.DateAvailability} returns this
 */
proto.mondo.v1.DateAvailability.prototype.setComplianceReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressComponents: (f = msg.getAddressComponents()) && proto.mondo.v1.AddressComponents.toObject(includeInstance, f),
    coordinate: (f = msg.getCoordinate()) && proto.mondo.v1.Coordinate.toObject(includeInstance, f),
    attributes: (f = msg.getAttributes()) && proto.mondo.v1.Location.Attributes.toObject(includeInstance, f),
    market: (f = msg.getMarket()) && proto.mondo.v1.Market.toObject(includeInstance, f),
    storeIdentifier: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.Location}
 */
proto.mondo.v1.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.Location;
  return proto.mondo.v1.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.Location}
 */
proto.mondo.v1.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = new proto.mondo.v1.AddressComponents;
      reader.readMessage(value,proto.mondo.v1.AddressComponents.deserializeBinaryFromReader);
      msg.setAddressComponents(value);
      break;
    case 3:
      var value = new proto.mondo.v1.Coordinate;
      reader.readMessage(value,proto.mondo.v1.Coordinate.deserializeBinaryFromReader);
      msg.setCoordinate(value);
      break;
    case 4:
      var value = new proto.mondo.v1.Location.Attributes;
      reader.readMessage(value,proto.mondo.v1.Location.Attributes.deserializeBinaryFromReader);
      msg.setAttributes(value);
      break;
    case 5:
      var value = new proto.mondo.v1.Market;
      reader.readMessage(value,proto.mondo.v1.Market.deserializeBinaryFromReader);
      msg.setMarket(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressComponents();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mondo.v1.AddressComponents.serializeBinaryToWriter
    );
  }
  f = message.getCoordinate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mondo.v1.Coordinate.serializeBinaryToWriter
    );
  }
  f = message.getAttributes();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mondo.v1.Location.Attributes.serializeBinaryToWriter
    );
  }
  f = message.getMarket();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mondo.v1.Market.serializeBinaryToWriter
    );
  }
  f = message.getStoreIdentifier();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.Location.Attributes.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.Location.Attributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.Location.Attributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Location.Attributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapSourceInfo: (f = msg.getMapSourceInfo()) && proto.mondo.v1.Location.Attributes.MapSourceInfo.toObject(includeInstance, f),
    isHaulAway: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.Location.Attributes}
 */
proto.mondo.v1.Location.Attributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.Location.Attributes;
  return proto.mondo.v1.Location.Attributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.Location.Attributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.Location.Attributes}
 */
proto.mondo.v1.Location.Attributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mondo.v1.Location.Attributes.MapSourceInfo;
      reader.readMessage(value,proto.mondo.v1.Location.Attributes.MapSourceInfo.deserializeBinaryFromReader);
      msg.setMapSourceInfo(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHaulAway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.Location.Attributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.Location.Attributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.Location.Attributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Location.Attributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapSourceInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mondo.v1.Location.Attributes.MapSourceInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsHaulAway();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.Location.Attributes.MapSourceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.Location.Attributes.MapSourceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    googlePlaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    googlePlaceTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.Location.Attributes.MapSourceInfo}
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.Location.Attributes.MapSourceInfo;
  return proto.mondo.v1.Location.Attributes.MapSourceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.Location.Attributes.MapSourceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.Location.Attributes.MapSourceInfo}
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGooglePlaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addGooglePlaceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.Location.Attributes.MapSourceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.Location.Attributes.MapSourceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGooglePlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGooglePlaceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string google_place_id = 1;
 * @return {string}
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.getGooglePlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.Location.Attributes.MapSourceInfo} returns this
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.setGooglePlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string google_place_types = 2;
 * @return {!Array<string>}
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.getGooglePlaceTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mondo.v1.Location.Attributes.MapSourceInfo} returns this
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.setGooglePlaceTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mondo.v1.Location.Attributes.MapSourceInfo} returns this
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.addGooglePlaceTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mondo.v1.Location.Attributes.MapSourceInfo} returns this
 */
proto.mondo.v1.Location.Attributes.MapSourceInfo.prototype.clearGooglePlaceTypesList = function() {
  return this.setGooglePlaceTypesList([]);
};


/**
 * optional MapSourceInfo map_source_info = 1;
 * @return {?proto.mondo.v1.Location.Attributes.MapSourceInfo}
 */
proto.mondo.v1.Location.Attributes.prototype.getMapSourceInfo = function() {
  return /** @type{?proto.mondo.v1.Location.Attributes.MapSourceInfo} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.Location.Attributes.MapSourceInfo, 1));
};


/**
 * @param {?proto.mondo.v1.Location.Attributes.MapSourceInfo|undefined} value
 * @return {!proto.mondo.v1.Location.Attributes} returns this
*/
proto.mondo.v1.Location.Attributes.prototype.setMapSourceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.Location.Attributes} returns this
 */
proto.mondo.v1.Location.Attributes.prototype.clearMapSourceInfo = function() {
  return this.setMapSourceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.Location.Attributes.prototype.hasMapSourceInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_haul_away = 7;
 * @return {boolean}
 */
proto.mondo.v1.Location.Attributes.prototype.getIsHaulAway = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.Location.Attributes} returns this
 */
proto.mondo.v1.Location.Attributes.prototype.setIsHaulAway = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.mondo.v1.Location.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.Location} returns this
 */
proto.mondo.v1.Location.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AddressComponents address_components = 2;
 * @return {?proto.mondo.v1.AddressComponents}
 */
proto.mondo.v1.Location.prototype.getAddressComponents = function() {
  return /** @type{?proto.mondo.v1.AddressComponents} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.AddressComponents, 2));
};


/**
 * @param {?proto.mondo.v1.AddressComponents|undefined} value
 * @return {!proto.mondo.v1.Location} returns this
*/
proto.mondo.v1.Location.prototype.setAddressComponents = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.Location} returns this
 */
proto.mondo.v1.Location.prototype.clearAddressComponents = function() {
  return this.setAddressComponents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.Location.prototype.hasAddressComponents = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Coordinate coordinate = 3;
 * @return {?proto.mondo.v1.Coordinate}
 */
proto.mondo.v1.Location.prototype.getCoordinate = function() {
  return /** @type{?proto.mondo.v1.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.Coordinate, 3));
};


/**
 * @param {?proto.mondo.v1.Coordinate|undefined} value
 * @return {!proto.mondo.v1.Location} returns this
*/
proto.mondo.v1.Location.prototype.setCoordinate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.Location} returns this
 */
proto.mondo.v1.Location.prototype.clearCoordinate = function() {
  return this.setCoordinate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.Location.prototype.hasCoordinate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Attributes attributes = 4;
 * @return {?proto.mondo.v1.Location.Attributes}
 */
proto.mondo.v1.Location.prototype.getAttributes = function() {
  return /** @type{?proto.mondo.v1.Location.Attributes} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.Location.Attributes, 4));
};


/**
 * @param {?proto.mondo.v1.Location.Attributes|undefined} value
 * @return {!proto.mondo.v1.Location} returns this
*/
proto.mondo.v1.Location.prototype.setAttributes = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.Location} returns this
 */
proto.mondo.v1.Location.prototype.clearAttributes = function() {
  return this.setAttributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.Location.prototype.hasAttributes = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Market market = 5;
 * @return {?proto.mondo.v1.Market}
 */
proto.mondo.v1.Location.prototype.getMarket = function() {
  return /** @type{?proto.mondo.v1.Market} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.Market, 5));
};


/**
 * @param {?proto.mondo.v1.Market|undefined} value
 * @return {!proto.mondo.v1.Location} returns this
*/
proto.mondo.v1.Location.prototype.setMarket = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.Location} returns this
 */
proto.mondo.v1.Location.prototype.clearMarket = function() {
  return this.setMarket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.Location.prototype.hasMarket = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string store_identifier = 6;
 * @return {string}
 */
proto.mondo.v1.Location.prototype.getStoreIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.Location} returns this
 */
proto.mondo.v1.Location.prototype.setStoreIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.AddressComponents.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.AddressComponents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.AddressComponents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.AddressComponents.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    province: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.AddressComponents}
 */
proto.mondo.v1.AddressComponents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.AddressComponents;
  return proto.mondo.v1.AddressComponents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.AddressComponents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.AddressComponents}
 */
proto.mondo.v1.AddressComponents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvince(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.AddressComponents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.AddressComponents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.AddressComponents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.AddressComponents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProvince();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.mondo.v1.AddressComponents.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.AddressComponents} returns this
 */
proto.mondo.v1.AddressComponents.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.mondo.v1.AddressComponents.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.AddressComponents} returns this
 */
proto.mondo.v1.AddressComponents.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.mondo.v1.AddressComponents.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.AddressComponents} returns this
 */
proto.mondo.v1.AddressComponents.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string province = 4;
 * @return {string}
 */
proto.mondo.v1.AddressComponents.prototype.getProvince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.AddressComponents} returns this
 */
proto.mondo.v1.AddressComponents.prototype.setProvince = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.mondo.v1.AddressComponents.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.AddressComponents} returns this
 */
proto.mondo.v1.AddressComponents.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.mondo.v1.AddressComponents.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.AddressComponents} returns this
 */
proto.mondo.v1.AddressComponents.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string neighborhood = 7;
 * @return {string}
 */
proto.mondo.v1.AddressComponents.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.AddressComponents} returns this
 */
proto.mondo.v1.AddressComponents.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.Coordinate.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.Coordinate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.Coordinate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Coordinate.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.Coordinate}
 */
proto.mondo.v1.Coordinate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.Coordinate;
  return proto.mondo.v1.Coordinate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.Coordinate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.Coordinate}
 */
proto.mondo.v1.Coordinate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.Coordinate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.Coordinate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.Coordinate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Coordinate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.mondo.v1.Coordinate.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.Coordinate} returns this
 */
proto.mondo.v1.Coordinate.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.mondo.v1.Coordinate.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.Coordinate} returns this
 */
proto.mondo.v1.Coordinate.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mondo.v1.Market.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.Market.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.Market.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.Market} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Market.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connectedMarketsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    timeZone: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.Market}
 */
proto.mondo.v1.Market.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.Market;
  return proto.mondo.v1.Market.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.Market} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.Market}
 */
proto.mondo.v1.Market.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addConnectedMarkets(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.Market.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.Market.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.Market} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.Market.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectedMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mondo.v1.Market.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.Market} returns this
 */
proto.mondo.v1.Market.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string connected_markets = 2;
 * @return {!Array<string>}
 */
proto.mondo.v1.Market.prototype.getConnectedMarketsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mondo.v1.Market} returns this
 */
proto.mondo.v1.Market.prototype.setConnectedMarketsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mondo.v1.Market} returns this
 */
proto.mondo.v1.Market.prototype.addConnectedMarkets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mondo.v1.Market} returns this
 */
proto.mondo.v1.Market.prototype.clearConnectedMarketsList = function() {
  return this.setConnectedMarketsList([]);
};


/**
 * optional string time_zone = 3;
 * @return {string}
 */
proto.mondo.v1.Market.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.Market} returns this
 */
proto.mondo.v1.Market.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mondo.v1.DirectionsRoute.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.DirectionsRoute.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.DirectionsRoute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.DirectionsRoute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.toObject = function(includeInstance, msg) {
  var f, obj = {
    units: jspb.Message.getFieldWithDefault(msg, 1, 0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    legsList: jspb.Message.toObjectList(msg.getLegsList(),
    proto.mondo.v1.DirectionsRoute.Leg.toObject, includeInstance),
    polyline: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.mondo.v1.Coordinate.toObject, includeInstance),
    features: (f = msg.getFeatures()) && proto.mondo.v1.DirectionsRoute.Features.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.DirectionsRoute}
 */
proto.mondo.v1.DirectionsRoute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.DirectionsRoute;
  return proto.mondo.v1.DirectionsRoute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.DirectionsRoute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.DirectionsRoute}
 */
proto.mondo.v1.DirectionsRoute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mondo.v1.DirectionsRoute.DistanceUnits} */ (reader.readEnum());
      msg.setUnits(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    case 4:
      var value = new proto.mondo.v1.DirectionsRoute.Leg;
      reader.readMessage(value,proto.mondo.v1.DirectionsRoute.Leg.deserializeBinaryFromReader);
      msg.addLegs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolyline(value);
      break;
    case 6:
      var value = new proto.mondo.v1.Coordinate;
      reader.readMessage(value,proto.mondo.v1.Coordinate.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    case 7:
      var value = new proto.mondo.v1.DirectionsRoute.Features;
      reader.readMessage(value,proto.mondo.v1.DirectionsRoute.Features.deserializeBinaryFromReader);
      msg.setFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.DirectionsRoute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.DirectionsRoute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.DirectionsRoute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLegsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.mondo.v1.DirectionsRoute.Leg.serializeBinaryToWriter
    );
  }
  f = message.getPolyline();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.mondo.v1.Coordinate.serializeBinaryToWriter
    );
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mondo.v1.DirectionsRoute.Features.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.mondo.v1.DirectionsRoute.DistanceUnits = {
  DISTANCE_UNITS_UNSPECIFIED: 0,
  DISTANCE_UNITS_METRIC: 1,
  DISTANCE_UNITS_IMPERIAL: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.DirectionsRoute.Leg.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.DirectionsRoute.Leg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.DirectionsRoute.Leg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.Leg.toObject = function(includeInstance, msg) {
  var f, obj = {
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.DirectionsRoute.Leg}
 */
proto.mondo.v1.DirectionsRoute.Leg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.DirectionsRoute.Leg;
  return proto.mondo.v1.DirectionsRoute.Leg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.DirectionsRoute.Leg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.DirectionsRoute.Leg}
 */
proto.mondo.v1.DirectionsRoute.Leg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.DirectionsRoute.Leg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.DirectionsRoute.Leg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.DirectionsRoute.Leg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.Leg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double distance = 1;
 * @return {number}
 */
proto.mondo.v1.DirectionsRoute.Leg.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.DirectionsRoute.Leg} returns this
 */
proto.mondo.v1.DirectionsRoute.Leg.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double duration = 2;
 * @return {number}
 */
proto.mondo.v1.DirectionsRoute.Leg.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.DirectionsRoute.Leg} returns this
 */
proto.mondo.v1.DirectionsRoute.Leg.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.DirectionsRoute.Features.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.DirectionsRoute.Features.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.DirectionsRoute.Features} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.Features.toObject = function(includeInstance, msg) {
  var f, obj = {
    outOfBoundary: (f = msg.getOutOfBoundary()) && proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.DirectionsRoute.Features}
 */
proto.mondo.v1.DirectionsRoute.Features.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.DirectionsRoute.Features;
  return proto.mondo.v1.DirectionsRoute.Features.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.DirectionsRoute.Features} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.DirectionsRoute.Features}
 */
proto.mondo.v1.DirectionsRoute.Features.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary;
      reader.readMessage(value,proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.deserializeBinaryFromReader);
      msg.setOutOfBoundary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.DirectionsRoute.Features.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.DirectionsRoute.Features.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.DirectionsRoute.Features} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.Features.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutOfBoundary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.prototype.toObject = function(opt_includeInstance) {
  return proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.toObject = function(includeInstance, msg) {
  var f, obj = {
    travelsOutOfBoundary: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    distanceOutOfBoundary: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary}
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary;
  return proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary}
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTravelsOutOfBoundary(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistanceOutOfBoundary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTravelsOutOfBoundary();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDistanceOutOfBoundary();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional bool travels_out_of_boundary = 1;
 * @return {boolean}
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.prototype.getTravelsOutOfBoundary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary} returns this
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.prototype.setTravelsOutOfBoundary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double distance_out_of_boundary = 2;
 * @return {number}
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.prototype.getDistanceOutOfBoundary = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary} returns this
 */
proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary.prototype.setDistanceOutOfBoundary = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional OutOfBoundary out_of_boundary = 1;
 * @return {?proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary}
 */
proto.mondo.v1.DirectionsRoute.Features.prototype.getOutOfBoundary = function() {
  return /** @type{?proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary, 1));
};


/**
 * @param {?proto.mondo.v1.DirectionsRoute.Features.OutOfBoundary|undefined} value
 * @return {!proto.mondo.v1.DirectionsRoute.Features} returns this
*/
proto.mondo.v1.DirectionsRoute.Features.prototype.setOutOfBoundary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.DirectionsRoute.Features} returns this
 */
proto.mondo.v1.DirectionsRoute.Features.prototype.clearOutOfBoundary = function() {
  return this.setOutOfBoundary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.DirectionsRoute.Features.prototype.hasOutOfBoundary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DistanceUnits units = 1;
 * @return {!proto.mondo.v1.DirectionsRoute.DistanceUnits}
 */
proto.mondo.v1.DirectionsRoute.prototype.getUnits = function() {
  return /** @type {!proto.mondo.v1.DirectionsRoute.DistanceUnits} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mondo.v1.DirectionsRoute.DistanceUnits} value
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
 */
proto.mondo.v1.DirectionsRoute.prototype.setUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double distance = 2;
 * @return {number}
 */
proto.mondo.v1.DirectionsRoute.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
 */
proto.mondo.v1.DirectionsRoute.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double duration = 3;
 * @return {number}
 */
proto.mondo.v1.DirectionsRoute.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
 */
proto.mondo.v1.DirectionsRoute.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Leg legs = 4;
 * @return {!Array<!proto.mondo.v1.DirectionsRoute.Leg>}
 */
proto.mondo.v1.DirectionsRoute.prototype.getLegsList = function() {
  return /** @type{!Array<!proto.mondo.v1.DirectionsRoute.Leg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mondo.v1.DirectionsRoute.Leg, 4));
};


/**
 * @param {!Array<!proto.mondo.v1.DirectionsRoute.Leg>} value
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
*/
proto.mondo.v1.DirectionsRoute.prototype.setLegsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mondo.v1.DirectionsRoute.Leg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mondo.v1.DirectionsRoute.Leg}
 */
proto.mondo.v1.DirectionsRoute.prototype.addLegs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mondo.v1.DirectionsRoute.Leg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
 */
proto.mondo.v1.DirectionsRoute.prototype.clearLegsList = function() {
  return this.setLegsList([]);
};


/**
 * optional string polyline = 5;
 * @return {string}
 */
proto.mondo.v1.DirectionsRoute.prototype.getPolyline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
 */
proto.mondo.v1.DirectionsRoute.prototype.setPolyline = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Coordinate points = 6;
 * @return {!Array<!proto.mondo.v1.Coordinate>}
 */
proto.mondo.v1.DirectionsRoute.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.mondo.v1.Coordinate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mondo.v1.Coordinate, 6));
};


/**
 * @param {!Array<!proto.mondo.v1.Coordinate>} value
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
*/
proto.mondo.v1.DirectionsRoute.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.mondo.v1.Coordinate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mondo.v1.Coordinate}
 */
proto.mondo.v1.DirectionsRoute.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.mondo.v1.Coordinate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
 */
proto.mondo.v1.DirectionsRoute.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};


/**
 * optional Features features = 7;
 * @return {?proto.mondo.v1.DirectionsRoute.Features}
 */
proto.mondo.v1.DirectionsRoute.prototype.getFeatures = function() {
  return /** @type{?proto.mondo.v1.DirectionsRoute.Features} */ (
    jspb.Message.getWrapperField(this, proto.mondo.v1.DirectionsRoute.Features, 7));
};


/**
 * @param {?proto.mondo.v1.DirectionsRoute.Features|undefined} value
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
*/
proto.mondo.v1.DirectionsRoute.prototype.setFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mondo.v1.DirectionsRoute} returns this
 */
proto.mondo.v1.DirectionsRoute.prototype.clearFeatures = function() {
  return this.setFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mondo.v1.DirectionsRoute.prototype.hasFeatures = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * @enum {number}
 */
proto.mondo.v1.ServiceAvailabilityReason = {
  SERVICE_AVAILABILITY_REASON_UNSPECIFIED: 0,
  SERVICE_AVAILABILITY_REASON_AVAILABLE: 1,
  SERVICE_AVAILABILITY_REASON_LOCATIONS_UNAVAILABLE_NOT_SAME_SERVICE_AREA: 2,
  SERVICE_AVAILABILITY_REASON_LOCATIONS_UNAVAILABLE_OUTSIDE_DELIVERABLE_SERVICE_AREA: 3,
  SERVICE_AVAILABILITY_REASON_LOCATIONS_UNAVAILABLE_OVER_LONG_RANGE_DELIVERY_LIMIT: 4,
  SERVICE_AVAILABILITY_REASON_LOCATIONS_UNAVAILABLE_ABIGUOUS_ADDRESS: 5,
  SERVICE_AVAILABILITY_REASON_LOCATIONS_UNAVAILABLE_COMPLIANCE: 6,
  SERVICE_AVAILABILITY_REASON_DATE_RANGE_UNAVAILABLE: 7,
  SERVICE_AVAILABILITY_REASON_DATE_UNAVAILABLE_STORE_HOURS: 8,
  SERVICE_AVAILABILITY_REASON_DATE_UNAVAILABLE_BLACKOUT: 9,
  SERVICE_AVAILABILITY_REASON_DATE_UNAVAILABLE_SUPPLY: 10
};

/**
 * @enum {number}
 */
proto.mondo.v1.ServiceAvailabilityComplianceReason = {
  SERVICE_AVAILABILITY_COMPLIANCE_REASON_UNSPECIFIED: 0,
  SERVICE_AVAILABILITY_COMPLIANCE_REASON_UTC_COMMERCIAL_LOCATION_REQUIRED: 1,
  SERVICE_AVAILABILITY_COMPLIANCE_REASON_UTC_TOO_MANY_LOCATIONS: 2,
  SERVICE_AVAILABILITY_COMPLIANCE_REASON_UTC_USECASE_NOT_ALLOWED: 3,
  SERVICE_AVAILABILITY_COMPLIANCE_REASON_UTC_HAULAWAY_NOT_ALLOWED: 4
};

goog.object.extend(exports, proto.mondo.v1);
