import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { GoogleLogout } from 'react-google-login';
import { googleOAuth2 } from '../actions/google-auth';
import { useHistory } from 'react-router-dom';

const clientId = '466642712127-ngnrn0skcoi8s0f7la0acfdlso4vu9im.apps.googleusercontent.com';

const GoogleLogoutLink = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    if (typeof (JSON.parse(localStorage.getItem('userInfo') || '{}')).accessToken == 'undefined') history.replace('/login');
  });

  return (
    <div>
      <GoogleLogout
        clientId={clientId}
        render={renderProps => (
          <button className="button is-text" onClick={renderProps.onClick} disabled={renderProps.disabled}>Sign Out</button>
        )}
        onLogoutSuccess={props.googleOAuth2}
      ></GoogleLogout>
    </div>
  );
};

function mapStateToProps (state: any) {
  return {
      ...state,
  };
}

function mapDispatchToProps (dispatch: Dispatch<AnyAction>) {
  return bindActionCreators ({ googleOAuth2 }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogoutLink);
