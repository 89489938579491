import _ from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { googleOAuth2 } from '../../../actions/google-auth'
import { DollyApiTokenGoogle, GetDollyApiTokenGoogle } from '../../../actions/dollyApi/get-dolly-api-token-google'
import { ServiceAvailabilityConfiguration } from '../../../gen/mondo/v1/common_pb'
import { findServiceAvailabilityConfigurations } from '../../../actions/mondoService/findServiceAvailabilityConfigurations'
import { getServiceAvailabilityConfiguration } from '../../../actions/mondoService/getServiceAvailabilityConfiguration'
import { makeMetadata } from '../../../actions/mondoService/makeMetadata'
import { ConfigurationServiceAvailabilityConfigurationsList } from './configurationServiceAvailabilityConfigurationsList'
import { ConfigurationServiceAvailabilityEditForm } from './configurationServiceAvailabilityEditForm'

const makeDefaultConfiguration = () => {
  const conf = new ServiceAvailabilityConfiguration()
  conf.setId('new')
  conf.setScope('new-scope')
  conf.setLabel('New Scope')
  conf.setActive(true)
  conf.setOverrideScope('Dolly')
  conf.setWeaverCustomerEntityId('e01182ba-521e-459c-bbb3-3f185cd4ff34')
  conf.setWeaverLaborEntityId('f7f849c2-a49c-4c20-a551-7d5f38eee6c0')
  conf.setOutsideServiceAreaDeliverableRange(50)
  conf.setLongRangeDeliveryLimit(50)
  return conf
}

const Page = (
  props: {
    googleReducer: any;
    googleOAuth2: ((response: never[]) => void)
  }
) => {
  const { googleReducer, googleOAuth2 } = props
  let { environment, id } = useParams<any>();


  const history = useHistory()
  const [dollyApiTokenGoogle, setDollyApiTokenGoogle] = useState<DollyApiTokenGoogle>({ valid: false, validateError: false, googleId: '', apitoken: '' })

  const [loading, setLoading] = useState<boolean>(true);
  const [configurationsListData, setConfigurationsListData] = useState<ServiceAvailabilityConfiguration[]>([])
  const [configurationData, setConfigurationData] = useState<ServiceAvailabilityConfiguration>(makeDefaultConfiguration())

  useEffect(() => {
    GetDollyApiTokenGoogle().then((result) => {
      const { valid, validateError } = result
      if (!valid || validateError) {
        googleOAuth2([])
        return
      }
      setDollyApiTokenGoogle(result)
    })
  }, [googleOAuth2, history])

  useEffect(() => {
    if (googleReducer === []) {
      history.replace('/login')
    }
  }, [googleReducer, history])

  const retrieveConfiguration = useCallback((configurationId: string) => {
    setLoading(true)
    let metadata = makeMetadata(environment, dollyApiTokenGoogle.apitoken)
    getServiceAvailabilityConfiguration(metadata, configurationId)
      .then(configuration => {
        setConfigurationData(configuration)
        setLoading(false)
      })
      .catch(err => {
        console.log(`Error loading configuration ${err}`)
        history.replace(`/product/configurationServiceAvailability/${environment}`)
      })
  }, [environment, dollyApiTokenGoogle, history])

  useEffect(() => {
    if (!dollyApiTokenGoogle.valid) {
      return
    }
    setLoading(true)
    if (!_.isEmpty(id)) {
      if (id === 'new') {
        setConfigurationData(makeDefaultConfiguration())
        setLoading(false)
        return
      }
      retrieveConfiguration(id)
      return
    }

    let metadata = makeMetadata(environment, dollyApiTokenGoogle.apitoken)
    findServiceAvailabilityConfigurations(metadata, false).then(configurations => {
      setConfigurationData(makeDefaultConfiguration())
      setConfigurationsListData(configurations)
      setLoading(false)
    }).catch(err => {
      console.log(`Error loading configuration ${err}`)
    })
  }, [dollyApiTokenGoogle, environment, id, history, retrieveConfiguration])

  return (
    <div className="content">
      {(loading &&
        <h1>Loading...</h1>
      ) || (
        <div className="section">
          {(!id &&
            <ConfigurationServiceAvailabilityConfigurationsList
            data={configurationsListData}
            environment={environment}
          />
          ) || (
              <ConfigurationServiceAvailabilityEditForm
                configuration={configurationData}
                environment={environment}
                dollyApiTokenGoogle={dollyApiTokenGoogle}
                retrieveConfiguration={retrieveConfiguration}
              />
            )}
        </div>
        )}
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    ...state,
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ googleOAuth2 }, dispatch)
}

const ProductConfigurationServiceAvailability = connect(mapStateToProps, mapDispatchToProps)(Page)
export { ProductConfigurationServiceAvailability }