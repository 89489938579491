import React from 'react';
import { Link } from 'react-router-dom';
import PanelBlock from '../../components/panel-block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Page = () => {
  return (
    <div>
      <section className="section pt-3 pb-0">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><Link to="/">Admin</Link></li>
              <li className="is-active"><Link to="/product">Product & Engineering</Link></li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="section">
        <div className="container">
        <div className="columns">
            <div className="column">
              <div className="box has-background-white-ter has-text-centered">
                <h2 className="title is-4">Product Support</h2>
                  <p>
                    View the decision flow for how to get support from the Dolly Product and Engineering teams.
                  </p>
                  <a href="https://whimsical.com/how-to-get-support-from-product-engineering-YEjefcPPpKZVx1Up3RCCeK" className="button is-info mt-3">
                      How To Get Support
                  </a>
              </div>
            </div>
            <div className="column">
              <div className="box has-background-white-ter has-text-centered" style={{height:'100%'}}>
                <h2 className="title is-4">IT Helpdesk</h2>
                  <p>
                    Support requests for IT can be submitted by email to <a href="mailto:support@c9s.ca">support@c9s.ca</a>, or if it is an emergency, staff can call our support line at +1 (604) 957-1555.
                  </p>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h2 className="title is-4">Tools</h2>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Analytics
                </p>
                <PanelBlock title="Amplitude (UX)" icon="users" url="https://analytics.amplitude.com/dolly/team-spaces" />
                <PanelBlock title="Apptimize (a/b testing)" icon="vials" url="https://apptimize.com/" />
                <PanelBlock title="Google Analytics" icon="google" iconPrefix="fab" url="https://analytics.google.com/analytics/web/#/home/a47314819w87453659p90800730/" />
                <PanelBlock title="Full Story" icon="ruler" url="https://app.fullstory.com" />
                <PanelBlock title="Metabase (Data & BI Portal)" icon="database" url="https://metabase.dolly.com" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Build, Deployment, Test, Monitor
                </p>
                <PanelBlock title="Circle CI" icon="circle-notch" url="https://app.circleci.com/projects/project-dashboard/github/DollyInc" />
                <PanelBlock title="Cypress" icon="shield-alt" url="https://dashboard.cypress.io/login" />
                <PanelBlock title="Github" icon="github" iconPrefix="fab" url="https://github.com/DollyInc" />
                <PanelBlock title="Data Dog" icon="table" url="https://app.datadoghq.com/dashboard/lists" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Integrations
                </p>
                <PanelBlock title="Twilio" icon="sms" url="https://docs.google.com/document/d/1FTYdbkynaGcIxXDx9LSAbxtnkN-SR7I-dwhm0dahS_c/edit?usp=sharing" />
                <PanelBlock title="ZenDrive" icon="question" url="https://docs.google.com/document/d/1EgXPHd2IZJaR_vCCLvvSsZNyuOfUHvDJ98JSVgID1Jk/edit?usp=sharing" />
                <div className="panel-block">
                  <span className="panel-icon">
                    <FontAwesomeIcon icon={['fab', 'google']} />
                  </span>
                  Google Maps (pending)
                </div>
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Planning
                </p>
                <PanelBlock title="Platform Value Proposition" icon="heart" url="https://docs.google.com/presentation/d/1IXxiORPqUPycRBe72UvYCRS9CyFiASHqaFQWCsi-vSc/edit#slide=id.g9854d8531b_0_29" />
                <PanelBlock title="Microservices Roadmap" icon="map" url="https://docs.google.com/spreadsheets/d/1hfSDMKksqr6xp1B7mWhkzMcdSKyCAi2L9RVUTdkDUFE/edit?usp=sharing" />
                <PanelBlock title="inVision" icon="invision" iconPrefix="fab" url="https://projects.invisionapp.com/" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Dolly Architecture
                </p>
                <PanelBlock title="Dolly Ecosystem" icon="sitemap" url="https://whimsical.com/ecosystem-QL8G7S1cZxfCtTATsS7q4B" />
                <PanelBlock title="Dolly Architecture" icon="sitemap" url="https://whimsical.com/dolly-tech-arch-G8BHRypUz3CWKXmYLXsjy8" />
                <PanelBlock title="Lighthouse Configuration" icon="sitemap" url="https://whimsical.com/partner-configuration-vision-UyARLrSNHKTmYVeht8wAqX" />
              </nav>
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Dolly Configuration
                </p>
                <PanelBlock title="Service Availability (Staging)" icon="cog" url="/product/configurationServiceAvailability/staging" />
                <PanelBlock title="Service Availability (Production)" icon="cogs" url="/product/configurationServiceAvailability/production" />
              </nav>
            </div>
            <div className="column">
              <h2 className="title is-4">Documentation</h2>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Team Practices
                </p>
                <PanelBlock title="Task Definition & Breakdown" icon="book" url="https://docs.google.com/presentation/d/1K0vQOWZ-KZ3jie7ZFvQhq8iq7nMQy9GNUepEYj6nj2M/edit?usp=sharing" />
                <PanelBlock title="Test Manifesto Deck" icon="book" url="https://docs.google.com/presentation/d/15FzBsGVznthog-7r5o6ss-hQEAWxwA4DJxc6bdkMXi0/edit?usp=sharing" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Pricing & Pay Related
                </p>
                <PanelBlock title="Adjustments" icon="book" url="https://docs.google.com/document/d/1IhFbVwYhvJuEJ9zkis4q98AAUWaAmj_7Wm4qqgM4C6M/edit?usp=sharing" />
                <PanelBlock title="Algorithm" icon="book" url="https://docs.google.com/document/d/10tU__2B_8F_LC6K3h8Y7cebTJgWua2u-tsNv84fAG0I/edit?usp=sharing" />
                <PanelBlock title="Flat Rate Apartments" icon="book" url="https://docs.google.com/document/d/1LoaiECE1lBP-8AtbeX2a8D5T84XJhUFPN_bUJqQUyEE/edit?usp=sharing" />
                <PanelBlock title="One-to-One Pay" icon="book" url="https://docs.google.com/document/d/1wZrynkpRJsG7-3ipmvHOdMMG2bJ6sikFHkQa81T4Y8Q/edit?usp=sharing" />
                <PanelBlock title="Pricing Policy" icon="book" url="https://docs.google.com/document/d/1aLQW4jaqHg-icaVTDal_h435H4bXJb3LN_Baf5hpl3w/edit?usp=sharing" />
                <PanelBlock title="Rate Card Configuration" icon="book" url="https://docs.google.com/document/d/1xM3N7RLv7MqdSn3ppGzKPlN9TlJXB4HwncHncvf0IkE/edit?usp=sharing" />
                <PanelBlock title="Rate Cards Defined" icon="book" url="https://docs.google.com/document/d/1xM3N7RLv7MqdSn3ppGzKPlN9TlJXB4HwncHncvf0IkE/edit?usp=sharing" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Key Feature Areas
                </p>
                <PanelBlock title="Assembly" icon="book" url="https://dolly.zendesk.com/hc/en-us/articles/360035096051-Assembly" />
                <PanelBlock title="Bid Buckets" icon="book" url="https://docs.google.com/document/d/1zPpKlgBVQ1TDvblAVe5moMNJ3KhCj2vl4ODl8VdzoY0/edit?usp=sharing" />
                <PanelBlock title="Broadcast, On Demand, Available Now" icon="book" url="https://docs.google.com/document/d/1kIa7c3NwH8pkc3wEDmqKEft0ki3m04Dx-v4f_txqJ-I/edit?usp=sharing" />
                <PanelBlock title="Costco On-Demand" icon="book" url="https://whimsical.com/costco-wireframes-XaJXpV5njRQ4NTREbVVXH" />
                <PanelBlock title="Extras" icon="book" url="https://docs.google.com/spreadsheets/d/1e75cqiVe9u-nyl-pe6rkh8la5WF35YwAhtPSljoYZEw/edit#gid=0" />
                <PanelBlock title="Lighthouse Training Manual" icon="book" url="https://docs.google.com/document/d/1brBh_Fme9Kbz3Fkm3XHD3lGkqumeEt4TmymSmsRZNGA/edit" />
                <PanelBlock title="Long Range Delivery (LRD)" icon="book" url="https://whimsical.com/lrd-use-cases-and-fee-structure-DLqfsSTinf5Ra4hEeMUopG" />
                <PanelBlock title="Routes" icon="book" url="https://docs.google.com/document/d/1N8sUsH94-KPPiEYunc0AHc9cQ9zx5meonamXcypVLiM/edit?usp=sharing" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                IT & Security
                </p>
                <PanelBlock title="Agile & Change Control Policy" icon="book" url="https://docs.google.com/document/d/1thWPX1noK5EhVFlzG0GWWIkV_-AeQeAPc6yQAr_46VU/edit?usp=sharing" />
                <PanelBlock title="Encryption Policy" icon="book" url="https://docs.google.com/document/d/1IoLbc-1gkDyoD26kXuj5SnfjZqEJ_Bni/edit?usp=sharing&ouid=106497554006127080519&rtpof=true&sd=true" />
                <PanelBlock title="End User IT Policy" icon="book" url="https://docs.google.com/document/d/1KP0WG-ODZ_-PJ0gJDU0eJ2zs7WN3Nu1V8J7eIU_jIm8/edit?usp=sharing" />
                <PanelBlock title="IT Operations Security Policy" icon="book" url="https://docs.google.com/document/d/1ZhHnQwla8H8qypOM0vTKeQ_twKqsg1CcooPTUlTmNKY/edit?usp=sharing" />
                <PanelBlock title="Risk Register" icon="book" url="https://docs.google.com/spreadsheets/d/1P7ZTInkCK_-oO4UGgU2BJKF_guW6ZbajfTXy09R8zDw/edit?usp=sharing " />
                <PanelBlock title="Vendor Assessment" icon="book" url="https://docs.google.com/spreadsheets/d/1KmKLdxtDy4Ek_XOLP51oK1OPb6LWFwujAO_6nxPlNR4/edit?usp=sharing" />
                <PanelBlock title="Vulnerability Management Policy" icon="book" url="https://docs.google.com/document/d/1DjNgYKIxaQQjr0WdKTJ-tTrA7qnwan1G6dsz2Rxk7Sw/edit?usp=sharing" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                Other
                </p>
                <PanelBlock title="Feature Scope Template" icon="book" url="https://docs.google.com/document/d/1kk0ivS9XeQUfZl8sGMEmjvQMUSCdCwsncgaUI_cAf6A/edit?usp=sharing" />
                <PanelBlock title="Incident Post Mortem Tracker" icon="book" url="https://docs.google.com/document/d/1qKyTH4Y4E-XdjiWfsGuzsMBQMNRDstXEVQSgZyYgjMU/edit" />
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page;
