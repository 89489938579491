import { grpc } from '@improbable-eng/grpc-web'
import { DOLLY_PLATFORM_URL } from '../../types/constants'
import { ServiceAvailabilityConfigurationService } from '../../gen/mondo/v1/service_availability_configuration_pb_service'
import { FindServiceAvailabilityConfigurationsRequest, FindServiceAvailabilityConfigurationsResponse } from '../../gen/mondo/v1/service_availability_configuration_pb'
import { ServiceAvailabilityConfiguration } from '../../gen/mondo/v1/common_pb'

const findServiceAvailabilityConfigurations = async (metadata: grpc.Metadata, retrieveInactive: boolean) => {
  return new Promise<ServiceAvailabilityConfiguration[]>((resolve, reject) => {
    const findServiceAvailabilityConfigurationsRequest = new FindServiceAvailabilityConfigurationsRequest()
    findServiceAvailabilityConfigurationsRequest.setRetrieveInactive(retrieveInactive)
    grpc.unary(ServiceAvailabilityConfigurationService.FindServiceAvailabilityConfigurations, {
      host: DOLLY_PLATFORM_URL,
      request: findServiceAvailabilityConfigurationsRequest,
      metadata: metadata,
      onEnd: res => {
        const { status, message, statusMessage } = res
        if (status === grpc.Code.OK && message) {
          const findServiceAvailabilityConfigurationsResponse = message as FindServiceAvailabilityConfigurationsResponse;
          resolve(findServiceAvailabilityConfigurationsResponse.getConfigurationsList())
        } else {
          reject(statusMessage)
        }
      }
    })
  })
}

export { findServiceAvailabilityConfigurations }
