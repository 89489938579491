import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Main from './containers/layouts/main';

// views
import Home from './containers/views/home';
import Login from './containers/views/login';

import HR from './containers/views/hr';
import Support from './containers/views/support';
import Helper from './containers/views/helper';
import Sales from './containers/views/sales';
import Marketing from './containers/views/marketing';
import Product from './containers/views/product';

import SupportReportingFulfillment from './containers/views/support/reportingFulfillment';

import { HelperReportingBidSelection } from './containers/views/helper/reportingBidSelection'

import { ProductConfigurationServiceAvailability } from './containers/views/product/configurationServiceAvailability'

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />

        <Route>
          <Main>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/hr" component={HR} />
              <Route path="/support">
                <Switch>
                  <Route exact path="/support/" component={Support} />
                  <Route path="/support/reportingFulfillment/" component={SupportReportingFulfillment} />
                </Switch>
              </Route>
              <Route path="/helper">
                <Switch>
                  <Route exact path="/helper/" component={Helper} />
                  <Route path="/helper/reportingBidSelection/" component={HelperReportingBidSelection} />
                </Switch>
              </Route>
              <Route path="/sales" component={Sales} />
              <Route path="/marketing" component={Marketing} />
              <Route path="/product">
                <Switch>
                  <Route exact path="/product/" component={Product} />
                  <Route path="/product/configurationServiceAvailability/:environment/:id" component={ProductConfigurationServiceAvailability} />
                  <Route path="/product/configurationServiceAvailability/:environment" component={ProductConfigurationServiceAvailability} />
                </Switch>
              </Route>

            </Switch>
          </Main>
        </Route>


      </Switch>
    </Router>
  );

};

export default Routes
