import { APISERVER } from '../../types/constants'

const fetchPost = async (fetchPath: string, body: string) => {
  const req = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  }
  const reqURL = APISERVER + fetchPath
  const res = await fetch(reqURL, req)
  return res.json()
}

export { fetchPost }