import React from 'react';
import Route from 'react-router-dom';
import Navigation from '../../components/common/navigation';
import Footer from '../../components/common/footer';

const Main = ({ children }: Route.RouteProps) => {
    return (
        <div>
            <Navigation />
            {children}
            <Footer />
        </div>
    );
};

export default Main;
