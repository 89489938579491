import { GOOGLE_OAUTH2 } from '../types/constants';

const initialState: never[] = [];

export const googleReducer = (state = initialState, action: { type: any; googleResponse: any }) => {
  switch (action.type) {
    case GOOGLE_OAUTH2: {
      return action.googleResponse;
    }
    default:
      return state;
  }
};
