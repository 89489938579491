import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IPanelBlock {
  iconPrefix?: any;
  icon: any;
  title: string;
  url: string;
}

const PanelBlock = ({iconPrefix, icon, title, url}: IPanelBlock) => {
  return (
    <a href={url} className="panel-block is-active">
      <span className="panel-icon">
        <FontAwesomeIcon icon={[iconPrefix, icon]} />
      </span>
      { title }
    </a>
  )
};

export default PanelBlock;
