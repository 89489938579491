import React from 'react';
import { Link } from 'react-router-dom';
import PanelBlock from '../../components/panel-block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Page = () => {
  return (
    <div>
      <section className="section pt-3 pb-0">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><Link to="/">Admin</Link></li>
              <li className="is-active"><Link to="/support">Support</Link></li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="box has-background-white-ter has-text-centered">
                <h2 className="title is-4">
                  <FontAwesomeIcon icon="book" />
                  <span className="pl-2">
                    Knowledge Base
                  </span>
                </h2>
                <p>
                  The ultimate support tool.  Period.
                </p>
                <a href="https://dolly.zendesk.com/hc/en-us" className="button is-info mt-3">
                  Enter Knowledge Base
                </a>
              </div>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  General
                </p>
                <PanelBlock title="Yelp (Respond to Reviews)" icon="yelp" iconPrefix="fab" url="https://www.yelp.com/login?return_url=%2F&sso=True" />
                <PanelBlock title="Homebase (scheduling)" icon="signal" url="https://app.joinhomebase.com/accounts/sign_in" />
                <PanelBlock title="Braintree" icon="paypal" iconPrefix="fab" url="https://www.braintreegateway.com/login" />
                <PanelBlock title="Zendesk" icon="headset" url="https://dolly.zendesk.com/agent/dashboard" />
                <PanelBlock title="Product Tickets" icon="ticket-alt" url="https://dolly.myjetbrains.com/youtrack/dashboard" />
              </nav>
            </div>
            <div className="column">
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Analytics
                </p>
                <PanelBlock title="Metabase (analytics)" icon="database" url="https://metabase.dolly.com" />
                <PanelBlock title="Full Story (analytics)" icon="ruler" url="https://app.fullstory.com" />
              </nav>
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Fraud Detection
                </p>
                <PanelBlock title="People Search Now" icon="search" url="https://www.peoplesearchnow.com" />
                <PanelBlock title="Kount" icon="shield-alt" url="https://portal.kount.net/login" />
              </nav>
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Fulfillment
                </p>
                <PanelBlock title="Fulfillment Map" icon="map-marked-alt" url="https://fulfillment-map.dolly.com" />
                <PanelBlock title="Helper Schedule" icon="map-marker" url="https://dolly.com/jobmap/schedule2.html" />
                <PanelBlock title="Fulfillment Tool (Metabase)" icon="map-marker-alt" url="https://metabase.dolly.com/question/287?Hours_Until_End=6" />
                <PanelBlock title="Fulfillment/Broadcast Reporting" icon="chart-bar" url="/support/reportingFulfillment" />
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page;
