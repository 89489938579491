import React from 'react';
import { Link } from 'react-router-dom';
import PanelBlock from '../../components/panel-block';

const Page = () => {
  return (
    <div>
      <section className="section pt-3 pb-0">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><Link to="/">Admin</Link></li>
              <li className="is-active"><Link to="/helper">Helper</Link></li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Communication
                </p>
                <PanelBlock title="Zendesk" icon="headset" url="https://dolly.zendesk.com/agent/" />
                <PanelBlock title="Zendesk Help Center" icon="question" url="https://dolly.zendesk.com/hc/en-us" />
                <PanelBlock title="Calendly" icon="calendar" url="https://calendly.com/" />
                <PanelBlock title="YouTube" icon="youtube" iconPrefix="fab" url="https://www.youtube.com/" />
                <PanelBlock title="Mailchimp" icon="mailchimp" iconPrefix="fab" url="https://us2.admin.mailchimp.com/" />
                <PanelBlock title="Chatitive" icon="comments" url="https://api.essential.to/bots/welcome/x0P5vTDQv_FE_-sZUFtzTA" />
                <PanelBlock title="Survey Monkey" icon="poll-h" url="https://www.surveymonkey.com/home/" />
                <PanelBlock title="Settings.html" icon="map-marked-alt" url="https://dolly.com/jobmap/settings.html" />
                <PanelBlock title="Blockers" icon="stop-circle" url="https://dolly.com/jobmap/blockerbroadcasts.html" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Onboarding
                </p>
                <PanelBlock title="Fountain" icon="briefcase" url="https://www.fountain.com/dolly" />
                <PanelBlock title="Vetty" icon="thumbs-up" url="https://client.vetty.co/client/dashboard" />
                <PanelBlock title="Checkr" icon="check" url="https://dashboard.checkr.com/candidates" />
                <PanelBlock title="" icon="agent" url="" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Pay
                </p>
                <PanelBlock title="PayPal" icon="paypal" iconPrefix="fab" url="https://www.paypal.com/mep/dashboard" />
                <PanelBlock title="Level" icon="layer-group" url="https://www.levelgoals.com/" />
                <PanelBlock title="Tax 1099" icon="coins" url="https://www.tax1099.com/" />
                <PanelBlock title="Payable" icon="money-check" url="https://payable.com/" />
                <PanelBlock title="Payouts.html" icon="money-check-alt" url="https://dolly.com/jobmap/payouts.html" />
              </nav>
            </div>
            <div className="column">
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Perks/Swag/Shipping
                </p>
                <PanelBlock title="Benefit Hub" icon="wind" url="https://dolly.benefithub.com/app" />
                <PanelBlock title="Bravo Branding" icon="exclamation" url="https://www.bravobranding.com/" />
                <PanelBlock title="Square Up" icon="square" url="https://squareup.com/dashboard/orders/overview" />
                <PanelBlock title="Pirate Ship" icon="skull-crossbones" url="https://ship.pirateship.com/" />
                <PanelBlock title="Stamps" icon="mail-bulk" url="https://www.stamps.com/" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Recruiting
                </p>
                <PanelBlock title="Craigslist" icon="peace" url="https://craigslist.org" />
                <PanelBlock title="Facebook Ads Manager" icon="facebook" iconPrefix="fab" url="https://business.facebook.com/adsmanager/manage/campaigns?act=16295840&business_id=871203786285483&columns=name%2Cerrors%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cactions%3Alink_click%2Cactions%3Amobile_app_install%2Cactions%3Aoffsite_conversion.fb_pixel_purchase%2Cactions%3Aonsite_conversion.purchase%2Cactions%3Aapp_custom_event.fb_mobile_purchase&attribution_windows=default&date=2019-11-01_2019-11-21%2Cthis_month" />
                <PanelBlock title="Appjobs" icon="user-md" url="https://partners.appjobs.com/admin/partner/job/1146/campaign" />
                <PanelBlock title="Indeed" icon="briefcase" url="https://employers.indeed.com/j#jobs" />
                <PanelBlock title="Jobble" icon="id-card" url="https://jobble.com/" />
                <PanelBlock title="Proedge" icon="cheese" url="https://www.prodege.com/" />
                <PanelBlock title="Brazen" icon="users" url="https://app.brazenconnect.com/cc/" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Tools
                </p>
                <PanelBlock title="Helper/Hands Training Requests" icon="chalkboard-teacher" url="https://form.asana.com/?k=93Iv9BE-DGh8I1R3tDvmZA&d=100202757237897" />
                <PanelBlock title="helpers.html" icon="people-carry" url="https://dolly.com/jobmap/helpers.html" />
                <PanelBlock title="Marget Manager" icon="store" url="http://dolly-react-bd-dashboard.s3-website-us-west-2.amazonaws.com/markets" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Reporting
                </p>
                <PanelBlock title="Bid Selection Reporting" icon="calendar-check" url="/helper/reportingBidSelection" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Fountain Backend
                </p>
                <PanelBlock title="Hellosign" icon="signature" url="https://app.hellosign.com/home/index" />
                <PanelBlock title="Vouched" icon="id-card" url="https://console.vouched.id/dashboard" />
                <PanelBlock title="Typeform" icon="poll-h" url="https://admin.typeform.com/accounts/01D8JWZWVACH0NDXVE7JFS2K7J/workspaces/12455772" />
                <PanelBlock title="Zapier" icon="link" url="https://zapier.com/app/dashboard" />
              </nav>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Server
                </p>
                <PanelBlock title="Sendgrid" icon="envelope-open-text" url="https://app.sendgrid.com/login" />
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page;
