import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useTable, useFilters, useSortBy, Row } from 'react-table'
import parse from 'html-react-parser'

const Styles = styled.div`
  table {
    th,
    td {
      :last-child {
        /* border-right: 0; */
        min-width: 28em;
      }
    }
  }

  .vehicle-icon {
    height: 1.1em;
    vertical-align: middle;
  }
  
  .people-icon {
    height: 1.1em;
    padding: 0 0.3em;
    vertical-align: middle;
  }

  .sort-asc::after {
    content: "▲"
  }

  .sort-desc::after {
    content: "▼"
  }
`

interface ReportingFulfillmentTableMissingBroadcastProps {
  data: any
}

const ReportingFulfillmentTableMissingBroadcast: React.FC<ReportingFulfillmentTableMissingBroadcastProps> = ({ data }) => {
  const renderHtml = useCallback((cell: any) => {
    return <div>{parse(cell.value)}</div>
  }, [])

  const columns = useMemo(() => {
    return [
      { Header: 'Name', accessor: 'helperName' },
      { Header: 'Email', accessor: 'helperEmail' },
      { Header: 'Helper#', accessor: 'helperNumber' },
      { Header: 'Capabilities', accessor: 'capabilitiesText', Cell: renderHtml },
      { Header: 'Broadcast State', accessor: 'broadcastStateText', minWidth: 200 },
    ]
  }, [renderHtml])

  const hiddenColumns = useMemo(() => {
    return []
  }, [])
  const sortBy = useMemo(() => {
    return [{ id: 'broadcastStateText', desc: true }]
  }, [])

  const initialState = { hiddenColumns, sortBy }

  // useTable "as any" since typescript for react-table is out-of-whack right now
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, initialState }, useFilters, useSortBy) as any


  return (
    <Styles>
      <div className="table-container">
        <table {...getTableProps()} className="table is-narrow is-hoverable is-bordered is-fullwidth tableWrap">
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row<object>) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Styles>
  )
}

export default ReportingFulfillmentTableMissingBroadcast;