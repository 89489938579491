import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState, useMemo } from 'react'//, useCallback
import { useHistory } from 'react-router-dom'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import DatePicker from 'react-date-picker';

import { googleOAuth2 } from '../../../actions/google-auth'
import { DollyApiTokenGoogle, GetDollyApiTokenGoogle } from '../../../actions/dollyApi/get-dolly-api-token-google'
import { getMarkets } from '../../../actions/dollyApi/getMarkets'
import { findHelpersCurated } from '../../../actions/dollyApi/findHelpersCurated'
import { REPORTING_BID_SELECTION_EXPLAINERS } from '../../../types/constants'

import { LabelledButton } from '../../../components/labelled-button'
import { LabelledSelect, Option } from '../../../components/labelled-select'

import { getReportActiveBids } from './reportingBidSelection/getReportActiveBids'
import { ReportingData, convertReportingDataToTableData } from './reportingBidSelection/reportingData'
import { ReportingBidSelectionTable } from './reportingBidSelectionTable'
import { ReportingBidSelectionSummaryTable } from './reportingBidSelectionSummaryTable'
import styles from './reportingBidSelection.module.css'



const Page = (
  props: {
    googleReducer: any;
    googleOAuth2: ((response: never[]) => void)
  }
) => {
  const { googleReducer, googleOAuth2 } = props

  const history = useHistory()
  const [dollyApiTokenGoogle, setDollyApiTokenGoogle] = useState<DollyApiTokenGoogle>({ valid: false, validateError: false, googleId: '', apitoken: '' })

  const [dataLoadedOnce, setDataLoadedOnce] = useState<boolean>(false)
  const [dataNeedsRefresh, setDataNeedsRefresh] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)

  const [dateTimeStart, setDateTimeStart] = useState<Date>(moment().subtract(7, 'days').toDate())
  const [dateTimeEnd, setDateTimeEnd] = useState<Date>(moment().endOf('day').toDate())

  const [filterHelperId, setFilterHelperId] = useState<boolean>(false)
  const [filterSelectionDescription, setFilterSelectionDescription] = useState<boolean>(false)

  const [marketOptions, setMarketOptions] = useState<Option[]>([{ value: '', html: 'Loading...' }])
  const [selectMarket, setSelectMarket] = useState<string>('')
  const [selectHelperCount, setSelectHelperCount] = useState<string>('')

  const [marketConnections, setMarketConnections] = useState<any>({})

  const [reportingData, setReportingData] = useState<ReportingData>({
    bids: [],
    helpersById: {}
  })

  // const [fulfillmentTableMissingBroadcastModel, setFulfillmentTableMissingBroadcastModel] = useState<boolean>(false)
  // const [missingBroadcastJobId, setMissingBroadcastJobId] = useState<string>('')

  const marketsToMarketOptions = (markets: any) => {
    const defaultOption = { value: '', html: '--' }
    if (typeof markets !== 'object' || markets === null) {
      return [defaultOption]
    }

    const marketOptions: Option[] = []
    // Just using market keys
    for (const market in markets) {
      marketOptions.push({ value: market, html: market })
    }
    marketOptions.sort((a, b) => { return a.html > b.html ? 1 : -1 })
    marketOptions.unshift(defaultOption)
    return marketOptions
  }

  const marketsToMarketConnections = (markets: any) => {
    const marketConnections = _.mapValues(markets, (market) => {
      const connections = _.isArray(market.market_connections) ? market.market_connections : []
      connections.push(market.name)
      return _.uniq(connections)
    })
    return marketConnections
  }

  const refreshReportingData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // sanity check with seattle
    if (typeof marketConnections['seattle'] === undefined) {
      console.log('marketConnections missing')
      return
    }

    setDataLoadedOnce(true)
    setDataNeedsRefresh(false)
    setDataLoading(true)

    const getReportActiveBidsQuery = {
      dateTimeAcceptedStart: dateTimeStart.toISOString(),
      dateTimeAcceptedEnd: dateTimeEnd.toISOString(),
    }

    Promise.all([
      getReportActiveBids(dollyApiTokenGoogle, getReportActiveBidsQuery),
      findHelpersCurated(dollyApiTokenGoogle, { retrieveContactInfo: true }),
    ]).then(([
      getReportActiveBidsResults,
      findHelpersCuratedResults
    ]) => {
      const { helpersById } = findHelpersCuratedResults
      setReportingData({
        bids: getReportActiveBidsResults,
        helpersById,
      })
      setDataLoading(false)
    })
  }
  const tableData = useMemo(() => {
    if (_.isEmpty(marketConnections) || _.isEmpty(reportingData.bids) || _.isEmpty(reportingData.helpersById)) {
      return []
    }
    return convertReportingDataToTableData(reportingData, marketConnections)
  }, [marketConnections, reportingData])

  const helperCountOptions = useMemo(() => {
    return [
      { html: '--', value: '' },
      { html: '1', value: '1' },
      { html: '2', value: '2' },
    ]
  }, [])

  useEffect(() => {
    GetDollyApiTokenGoogle().then((result) => {
      const { valid, validateError } = result
      if (!valid || validateError) {
        googleOAuth2([])
        return
      }
      setDollyApiTokenGoogle(result)
    })
  }, [googleOAuth2, history])

  useEffect(() => {
    if (googleReducer === []) {
      history.replace('/login')
    }
  }, [googleReducer, history])

  useEffect(() => {
    if (!dollyApiTokenGoogle.valid) {
      return
    }
    getMarkets(dollyApiTokenGoogle).then((markets) => {
      setMarketOptions(marketsToMarketOptions(markets))
      setMarketConnections(marketsToMarketConnections(markets))
    })
  }, [dollyApiTokenGoogle])

  useEffect(() => {
    setDataNeedsRefresh(true)
  }, [dateTimeStart, dateTimeEnd])

  const listItemsSelectionExplainer = _.map(REPORTING_BID_SELECTION_EXPLAINERS, (explainer: string, key: string) =>
    <li>{key} - {explainer}</li>
  )

  return (
    <div className={["content", styles.containerTop].join(' ')}>
      <div>
        <div className={styles.containerHoriz}>
          <h1 style={{ margin: "0 1em 0 0" }} className="title">Bid Selection Reporting</h1>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Range:</label>
            </div>
            <div className="field-body is-narrow">
              <div className="input" style={{ marginRight: "1em" }}>
                <DatePicker
                  onChange={(value) => setDateTimeStart(_.isArray(value) ? _.first(value) || new Date() : value)}
                  value={dateTimeStart}
                  returnValue="start"
                />
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">-</label>
            </div>
            <div className="field-body is-narrow">
              <div className="input" style={{ marginRight: "1em" }}>
                <DatePicker
                  onChange={(value) => setDateTimeEnd(_.isArray(value) ? _.last(value) || new Date() : value)}
                  value={dateTimeEnd}
                  returnValue="end"
                />
              </div>
            </div>
          </div>
          {
            (dataLoading &&
              <div id="fulfillment-table-loading-text" style={{ float: "right", fontWeight: "bold" }}>
                Loading...
                </div>)
            || (!dataLoadedOnce &&
              <LabelledButton label="Initial&nbsp;load:" buttonText="Load Data" buttonClasses={["is-info"]} action={refreshReportingData} />)
            || (dataNeedsRefresh &&
              <LabelledButton label="Refresh&nbsp;required:" buttonText="Refresh Data" buttonClasses={["is-info"]} action={refreshReportingData} />
            )
            || (<LabelledButton label="" buttonText="Reload" buttonClasses={["is-info"]} action={refreshReportingData} />)
          }
        </div>
        <h4 style={{ margin: "5px" }} className="subtitle">Visibility into Dolly bid selection for Helpers &amp; Hands.</h4>
        <div className={styles.containerHoriz} style={{ justifyContent: "space-around", padding: "10px 0" }}>
          <LabelledSelect
            label="Market:"
            id="fulfillment-table-market-select"
            options={marketOptions}
            selectedOption={selectMarket}
            action={(e: React.ChangeEvent<HTMLSelectElement>) => { setSelectMarket(e.currentTarget.value) }}
          />
          <LabelledSelect
            label="Helper&nbsp;Count:"
            id="fulfillment-table-helpercount-select"
            options={helperCountOptions}
            selectedOption={selectHelperCount}
            action={(e: React.ChangeEvent<HTMLSelectElement>) => { setSelectHelperCount(e.currentTarget.value) }}
          />
          <label className="checkbox">
            <input type="checkbox" id="show-selection-description" value="1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setFilterHelperId(e.currentTarget.checked) }} />
            &nbsp;Show Helper#
          </label>
          <label className="checkbox">
            <input type="checkbox" id="show-selection-description" value="1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setFilterSelectionDescription(e.currentTarget.checked) }} />
            &nbsp;Show Selection Type Descriptions
          </label>
        </div>
      </div>
      <div id="fulfillment-table"></div>
      <ReportingBidSelectionTable
        data={tableData}
        filters={{ filterHelperId, filterSelectionDescription, selectMarket, selectHelperCount }}
      />
      <div>&nbsp;</div>
      <ReportingBidSelectionSummaryTable
        data={tableData}
        filters={{ selectMarket, selectHelperCount }}
      />
      <div>&nbsp;</div>
      <div className="content is-normal" style={{ maxWidth: "80em", margin: "auto" }}>
        <h1>How this works</h1>
        <p>A Dolly bid selection goes through several different prioritized approaches, accepting the first valid helper or helper/hands pair:</p>
        <ul>
          {listItemsSelectionExplainer}
        </ul>
        <p>These selection types involve circumventing priorities: helperAssign, forceAccept, voucherRedeemed</p>
        <h2>Current Priorities:</h2>
        <p>1 Helper Dollys: ['gimmeprimary', 'helper', 'handshelper', 'hand', 'hand', 'helperhand']</p>
        <p>2 Helper Dollys: ['gimmeprimary', 'gimmesecondary', 'conditionalhelper', 'helper', 'hand', 'hand', 'helperhand', 'handshelper', 'splithelper', 'splithelper']</p>
        <p>Some priorities are listed twice for 2 Helper Dollys due to those priorities choosing only one helper/hands per iteration</p>
        <h4>Previous Priorities (before 05/12/2021):</h4>
        <p>1 Helper Dollys: ['gimmeprimary', 'helper', 'handshelper', 'hand', 'hand', 'helperhand']</p>
        <p>2 Helper Dollys: ['gimmeprimary', 'gimmesecondary', 'conditionalhelper', 'handshelper', 'helper', 'hand', 'hand', 'helperhand', 'splithelper', 'splithelper']</p>
        <p>&nbsp;</p>
        <p>Click <strong>Load Data</strong> above for initial data load.</p>
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    ...state,
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ googleOAuth2 }, dispatch)
}

const HelperReportingBidSelection = connect(mapStateToProps, mapDispatchToProps)(Page)
export { HelperReportingBidSelection }
export type { ReportingData }