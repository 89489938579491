import { DollyApiTokenGoogle } from './get-dolly-api-token-google'
import { fetchPost } from './fetchPost'

const getMarkets = async (dollyApiTokenGoogle: DollyApiTokenGoogle) => {
  const { apitoken, googleId } = dollyApiTokenGoogle
  const body = JSON.stringify({
    apitoken,
    googleId,
    fields: ["name"]
  })

  const markets = await fetchPost('/v2/app/markets', body)
  return markets
}

export { getMarkets }