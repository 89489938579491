import _ from 'lodash'
import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useTable, useFilters, useSortBy, Row } from 'react-table'
import parse from 'html-react-parser'

const Styles = styled.div`
  .vehicle-icon {
    height: 1.1em;
    vertical-align: middle;
  }
  
  .people-icon {
    height: 1.1em;
    padding: 0 0.3em;
    vertical-align: middle;
  }

  .sort-asc::after {
    content: "▲"
  }

  .sort-desc::after {
    content: "▼"
  }
`

interface ReportingTableProps {
  data: any
  filters: {
    selectMarket: string
    selectHelperCount: string
  }
}

const ReportingBidSelectionSummaryTable: React.FC<ReportingTableProps> = ({ data, filters }) => {
  const renderHtml = useCallback((cell: any) => {
    return <div>{parse(cell.value)}</div>
  }, [])

  const {
    selectMarket,
    selectHelperCount,
  } = filters

  const summaryData = useMemo(() => {
    const counts: { [key: string]: number } = {}
    for (const datum of data) {
      if (selectMarket && selectMarket !== datum.marketName) continue
      if (selectHelperCount && selectHelperCount !== datum.jobOrGroupHelperCount) continue

      const acceptedSelectionType = datum.acceptedSelectionType || 'unknown'
      counts[acceptedSelectionType] = (counts[acceptedSelectionType] || 0) + 1
    }
    return _.map(counts, (count, key) => {
      return { selectionTypeText: key, countText: count.toString() }
    })
  }, [data, selectMarket, selectHelperCount])

  const columns = useMemo(() => {
    return [
      { Header: 'Selection Type', accessor: 'selectionTypeText', Cell: renderHtml },
      { Header: 'Count', accessor: 'countText' },
    ]
  }, [renderHtml])

  const hiddenColumns = useMemo(() => {
    return []
  }, [])
  const sortBy = useMemo(() => {
    return [{ id: 'countText', desc: true }]
  }, [])

  const initialState = { hiddenColumns, sortBy }

  // useTable "as any" since typescript for react-table is out-of-whack right now
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: columns as any, data: summaryData, initialState }, useFilters, useSortBy) as any

  return (
    <Styles>
      <div className="table-container">
        <table {...getTableProps()} className="table is-narrow is-hoverable is-bordered tableWrap">
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row<object>) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Styles>
  )
}

export { ReportingBidSelectionSummaryTable }