import _ from 'lodash'

import {
  getJobMarket,
  getHelperName,
  getHelperEmail,
  getHelperNumber,
  getHelperCapabilitiesIcons,
} from '../../../../actions/dollyApi/dollyDataFunctions'

interface ReportingFulfillmentData {
  jobs: any
  jobCounts: any
  helperIdsByMarket: any
  helperCategoryCounts: any
  helpersById: any
}

function convertReportingFulfillmentDataToMissingBroadcastTableData(reportingFulfillmentData: ReportingFulfillmentData, marketConnections: string[], jobId: string) {
  const {
    jobs,
    jobCounts,
    helperIdsByMarket,
    helpersById,
  } = reportingFulfillmentData

  const job = _.find(jobs, ['_id', jobId])
  if (!job) {
    return []
  }

  const jobMarket = getJobMarket(job)

  const tableData = []

  const currentJobCounts = jobCounts[jobId] || {}
  const {
    broadcastedHelpers = [],
    broadcastHelpers = [],
    visibleHelpers = [],
  } = currentJobCounts

  const connectedMarkets = !_.isEmpty(marketConnections[jobMarket]) ? marketConnections[jobMarket] : [jobMarket]
  let helpers: string[] = []
  _.forEach(connectedMarkets, (market) => {
    helpers = _.concat(helpers, (helperIdsByMarket[market] || []))
  })

  for (const helperId of helpers) {
    if (visibleHelpers.includes(helperId)) {
      continue
    }
    const helper = helpersById[helperId]
    const helperName = getHelperName(helper)
    const helperEmail = getHelperEmail(helper)
    const helperNumber = getHelperNumber(helper)
    const capabilitiesText = getHelperCapabilitiesIcons(helper)

    const broadcasted = broadcastedHelpers.includes(helperId)
    const broadcast = broadcastHelpers.includes(helperId)
    let broadcastStateText = 'No Bid Window Generated'
    if (broadcast) {
      broadcastStateText = 'Broadcast, has current bid window, but not visible in list'
    } else if (broadcasted) {
      broadcastStateText = 'Broadcast, but no current bid window'
    }

    const datum = {
      helperName,
      helperEmail,
      helperNumber,
      capabilitiesText,
      broadcastStateText,
    }
    tableData.push(datum)
  }
  return tableData
}

export { convertReportingFulfillmentDataToMissingBroadcastTableData }