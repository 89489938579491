import _ from 'lodash'
import moment from 'moment-timezone'

import {
  TEAM_ICON,
  SINGLE_HELPER_ICON,
  HANDS_ICON,
  PICKUP_TRUCK_ICON,
  CARGO_VAN_ICON,
  BOX_TRUCK_ICON,
} from '../../../../types/constants'

import {
  getVehicleIcon,
  getPersonIcon,
} from '../../../../actions/dollyApi/dollyDataFunctions'

interface ReportingFulfillmentData {
  jobs: any
  jobCounts: any
  helperIdsByMarket: any
  helperCategoryCounts: any
  helpersById: any
}

function convertReportingFulfillmentDataToTableData(reportingFulfillmentData: ReportingFulfillmentData, marketConnections: string[]) {
  const {
    jobs,
    jobCounts,
    helperCategoryCounts,
  } = reportingFulfillmentData

  const nbsp = '&nbsp;'

  const formatCount = (counts: number, countTotals: number) => {
    if (!counts || !countTotals) {
      return (counts || '-').toString()
    }
    let countsText = Math.round(counts / countTotals * 100).toString() + '%'
    return '<span title=' + counts.toString() + '>' + countsText + '</span>'
  }

  const tableData = []
  for (const job of jobs) {
    const jobId = job._id
    if (!jobId) {
      continue
    }

    const durationEstimate = _.get(job, 'details.duration_estimate', '')
    const groupId = _.get(job, 'groups[0]._id', '')
    const groupNumber = _.get(job, 'groups[0].jobgroup_number', '')
    const helperCount = _.get(job, 'details.helper_count', '')
    const personCount = _.get(job, 'details.person_count', '')
    const {
      job_number: jobNumber = '',
      status = '',
      market_name: marketName = 'no_market',
      type: useCaseType = '',
      vehicle_type: vehicleType = '',
      start_date: startDate = '',
      business,
    } = job

    const jobNumberText = jobNumber + (groupNumber ? '<br>Group#<br>' + groupNumber : '')

    const durationEstimateText = durationEstimate ? durationEstimate.toString() + nbsp + 'min.' : '-'
    const dateTimeText = `<!--${moment(startDate).format()}-->` + moment(startDate).format('YYYY-MM-DD hh:mmA') + '<br>Estimated: ' + durationEstimateText

    const helperNeedsText = `<!--${personCount}${helperCount}${vehicleType}-->` + getPersonIcon(helperCount, personCount) + nbsp + getVehicleIcon(vehicleType)

    let marketKnownCount = helperCategoryCounts[marketName] || 0
    let marketTier1KnownCount = helperCategoryCounts[marketName + '__tier_1'] || 0
    let marketTier2KnownCount = helperCategoryCounts[marketName + '__tier_2'] || 0
    let marketTier3KnownCount = helperCategoryCounts[marketName + '__tier_3'] || 0
    let marketTier4KnownCount = helperCategoryCounts[marketName + '__tier_4'] || 0
    let marketTeamKnownCount = helperCategoryCounts[marketName + '__team'] || 0
    let marketPrimaryKnownCount = helperCategoryCounts[marketName + '__primary'] || 0
    let marketSecondaryKnownCount = helperCategoryCounts[marketName + '__secondary'] || 0
    let marketPickupTruckKnownCount = helperCategoryCounts[marketName + '__pickup_truck'] || 0
    let marketCargoVanKnownCount = helperCategoryCounts[marketName + '__cargo_van'] || 0
    let marketBoxTruckKnownCount = helperCategoryCounts[marketName + '__box_truck'] || 0

    const connectedMarkets = marketConnections[marketName]
    if (_.isArray(connectedMarkets) && !_.isEmpty(connectedMarkets)) {
      for (const connectedMarket of connectedMarkets) {
        if (marketName === connectedMarket) {
          continue
        }
        marketKnownCount += helperCategoryCounts[connectedMarket] || 0
        marketTier1KnownCount += helperCategoryCounts[connectedMarket + '__tier_1'] || 0
        marketTier2KnownCount += helperCategoryCounts[connectedMarket + '__tier_2'] || 0
        marketTier3KnownCount += helperCategoryCounts[connectedMarket + '__tier_3'] || 0
        marketTier4KnownCount += helperCategoryCounts[connectedMarket + '__tier_4'] || 0
        marketTeamKnownCount += helperCategoryCounts[connectedMarket + '__team'] || 0
        marketPrimaryKnownCount += helperCategoryCounts[connectedMarket + '__primary'] || 0
        marketSecondaryKnownCount += helperCategoryCounts[connectedMarket + '__secondary'] || 0
        marketPickupTruckKnownCount += helperCategoryCounts[connectedMarket + '__pickup_truck'] || 0
        marketCargoVanKnownCount += helperCategoryCounts[connectedMarket + '__cargo_van'] || 0
        marketBoxTruckKnownCount += helperCategoryCounts[connectedMarket + '__box_truck'] || 0
      }
    }

    const currentJobCounts = jobCounts[jobId] || {}
    let {
      all__had = 0,
      tier_1__had = 0,
      tier_2__had = 0,
      tier_3__had = 0,
      tier_4__had = 0,
      team__had = 0,
      primary__had = 0,
      secondary__had = 0,
      pickup_truck__had = 0,
      cargo_van__had = 0,
      box_truck__had = 0,
      all__has = 0,
      tier_1__has = 0,
      tier_2__has = 0,
      tier_3__has = 0,
      tier_4__has = 0,
      team__has = 0,
      primary__has = 0,
      secondary__has = 0,
      pickup_truck__has = 0,
      cargo_van__has = 0,
      box_truck__has = 0,
      all__vis = 0,
      tier_1__vis = 0,
      tier_2__vis = 0,
      tier_3__vis = 0,
      tier_4__vis = 0,
      team__vis = 0,
      primary__vis = 0,
      secondary__vis = 0,
      pickup_truck__vis = 0,
      cargo_van__vis = 0,
      box_truck__vis = 0,
    } = currentJobCounts

    let bidWindowsBroadcastedText = '*' + nbsp + formatCount(all__had, marketKnownCount)
    bidWindowsBroadcastedText += nbsp + TEAM_ICON + nbsp + formatCount(team__had, marketTeamKnownCount)
    bidWindowsBroadcastedText += nbsp + SINGLE_HELPER_ICON + nbsp + formatCount(primary__had, marketPrimaryKnownCount)
    bidWindowsBroadcastedText += nbsp + HANDS_ICON + nbsp + formatCount(secondary__had, marketSecondaryKnownCount)
    bidWindowsBroadcastedText += '<br>'
    bidWindowsBroadcastedText += nbsp + 'T1' + nbsp + formatCount(tier_1__had, marketTier1KnownCount)
    bidWindowsBroadcastedText += nbsp + 'T2' + nbsp + formatCount(tier_2__had, marketTier2KnownCount)
    bidWindowsBroadcastedText += nbsp + 'T3' + nbsp + formatCount(tier_3__had, marketTier3KnownCount)
    bidWindowsBroadcastedText += nbsp + 'T4' + nbsp + formatCount(tier_4__had, marketTier4KnownCount)
    bidWindowsBroadcastedText += '<br>'
    bidWindowsBroadcastedText += nbsp + PICKUP_TRUCK_ICON + nbsp + formatCount(pickup_truck__had, marketPickupTruckKnownCount)
    bidWindowsBroadcastedText += nbsp + CARGO_VAN_ICON + nbsp + formatCount(cargo_van__had, marketCargoVanKnownCount)
    bidWindowsBroadcastedText += nbsp + BOX_TRUCK_ICON + nbsp + formatCount(box_truck__had, marketBoxTruckKnownCount)

    let bidWindowsBroadcastText = '*' + nbsp + formatCount(all__has, marketKnownCount)
    bidWindowsBroadcastText += nbsp + TEAM_ICON + nbsp + formatCount(team__has, marketTeamKnownCount)
    bidWindowsBroadcastText += nbsp + SINGLE_HELPER_ICON + nbsp + formatCount(primary__has, marketPrimaryKnownCount)
    bidWindowsBroadcastText += nbsp + HANDS_ICON + nbsp + formatCount(secondary__has, marketSecondaryKnownCount)
    bidWindowsBroadcastText += '<br>'
    bidWindowsBroadcastText += nbsp + 'T1' + nbsp + formatCount(tier_1__has, marketTier1KnownCount)
    bidWindowsBroadcastText += nbsp + 'T2' + nbsp + formatCount(tier_2__has, marketTier2KnownCount)
    bidWindowsBroadcastText += nbsp + 'T3' + nbsp + formatCount(tier_3__has, marketTier3KnownCount)
    bidWindowsBroadcastText += nbsp + 'T4' + nbsp + formatCount(tier_4__has, marketTier4KnownCount)
    bidWindowsBroadcastText += '<br>'
    bidWindowsBroadcastText += nbsp + PICKUP_TRUCK_ICON + nbsp + formatCount(pickup_truck__has, marketPickupTruckKnownCount)
    bidWindowsBroadcastText += nbsp + CARGO_VAN_ICON + nbsp + formatCount(cargo_van__has, marketCargoVanKnownCount)
    bidWindowsBroadcastText += nbsp + BOX_TRUCK_ICON + nbsp + formatCount(box_truck__has, marketBoxTruckKnownCount)

    let bidWindowsBroadcastVisibleText = '*' + nbsp + formatCount(all__vis, marketKnownCount)
    bidWindowsBroadcastVisibleText += nbsp + TEAM_ICON + nbsp + formatCount(team__vis, marketTeamKnownCount)
    bidWindowsBroadcastVisibleText += nbsp + SINGLE_HELPER_ICON + nbsp + formatCount(primary__vis, marketPrimaryKnownCount)
    bidWindowsBroadcastVisibleText += nbsp + HANDS_ICON + nbsp + formatCount(secondary__vis, marketSecondaryKnownCount)
    bidWindowsBroadcastVisibleText += '<br>'
    bidWindowsBroadcastVisibleText += nbsp + 'T1' + nbsp + formatCount(tier_1__vis, marketTier1KnownCount)
    bidWindowsBroadcastVisibleText += nbsp + 'T2' + nbsp + formatCount(tier_2__vis, marketTier2KnownCount)
    bidWindowsBroadcastVisibleText += nbsp + 'T3' + nbsp + formatCount(tier_3__vis, marketTier3KnownCount)
    bidWindowsBroadcastVisibleText += nbsp + 'T4' + nbsp + formatCount(tier_4__vis, marketTier4KnownCount)
    bidWindowsBroadcastVisibleText += '<br>'
    bidWindowsBroadcastVisibleText += nbsp + PICKUP_TRUCK_ICON + nbsp + formatCount(pickup_truck__vis, marketPickupTruckKnownCount)
    bidWindowsBroadcastVisibleText += nbsp + CARGO_VAN_ICON + nbsp + formatCount(cargo_van__vis, marketCargoVanKnownCount)
    bidWindowsBroadcastVisibleText += nbsp + BOX_TRUCK_ICON + nbsp + formatCount(box_truck__vis, marketBoxTruckKnownCount)

    const missingHelperCount = marketKnownCount - all__vis

    const buttonsText = `Review Missing (${missingHelperCount})`

    const datum = {
      jobId,
      jobNumberText,
      status,
      marketName,
      useCaseType,
      helperNeedsText,
      dateTimeText,
      groupId,
      bidWindowsBroadcastedText,
      bidWindowsBroadcastText,
      bidWindowsBroadcastVisibleText,
      missingHelperCount,
      buttonsText,
      business,
    }
    tableData.push(datum)
  }
  return tableData
}

export { convertReportingFulfillmentDataToTableData }