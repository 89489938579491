import React from 'react';
import { Link } from 'react-router-dom';
import PanelBlock from '../../components/panel-block';

const Page = () => {
  return (
    <div>
      <section className="section pt-3 pb-0">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><Link to="/">Admin</Link></li>
              <li className="is-active"><Link to="/sales">Sales</Link></li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Tools
                </p>
                <PanelBlock title="BD Account Owners" icon="hands-helping" url="https://docs.google.com/spreadsheets/d/1-dMPRUORL5I8Jak94cTq75MMVIHw1ovZcazauVrIW3U/edit" />
                <PanelBlock title="PipeDrive (CRM)" icon="address-book" url="https://www.pipedrive.com" />
                <PanelBlock title="Zendesk" icon="headset" url="https://dolly.zendesk.com/agent/dashboard" />
                <PanelBlock title="Product Tickets" icon="ticket-alt" url="https://dolly.myjetbrains.com" />
                <PanelBlock title="LinkedIn" icon="linkedin" iconPrefix="fab" url="https://www.linkedin.com/company/dolly-inc-/" />
                <PanelBlock title="Seamless AI (find emails and phone numbers)" icon="search" url="https://www.seamless.ai/" />
                <PanelBlock title="BombBomb (videos)" icon="play" url="https://bombbomb.com/" />
              </nav>
            </div>
            <div className="column">
              <div className="box has-background-white-ter has-text-centered">
                <h2 className="title is-4">Enterprise Launch Checklist</h2>
                <p>
                  The complete on-boarding process for new retailers.
                </p>
                <a href="https://docs.google.com/document/d/1IRHpXJz-twJjdaEks4GojK-HJQxjIzGteCOA2cuLFhM/edit" className="button is-info mt-3">
                  View Checklist
                </a>
              </div>

              <nav className="panel is-radiusless">
                <p className="panel-heading is-radiusless">
                  Documenation
                </p>
                <PanelBlock title="Lighthouse - Customer Communication" icon="file-download" url="lighthouse-customer-communication.pptx" />
                <PanelBlock title="Lighthouse - Dolly Lifecycle" icon="recycle" url="https://docs.google.com/presentation/d/1NH1uiin5IbW_HY4vlqf60IuOKQp3DPoQ6edpk0EbLbg/edit" />
                <PanelBlock title="Remittance Info for Vendors" icon="file-invoice-dollar" url="https://docs.google.com/document/d/1OLP5WHvcj2Bhgy3lzGpOC2M4S8193kyiUdoXrN2RPuA/edit" />
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page;
