import React, { useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useTable, useFilters, useSortBy, Row } from 'react-table'
import parse from 'html-react-parser'

const Styles = styled.div`
  .vehicle-icon {
    height: 1.1em;
    vertical-align: middle;
  }
  
  .people-icon {
    height: 1.1em;
    padding: 0 0.3em;
    vertical-align: middle;
  }

  .sort-asc::after {
    content: "▲"
  }

  .sort-desc::after {
    content: "▼"
  }
`

interface ReportingFulfillmentTableProps {
  data: any
  filters: {
    filterPastBroadcasts: boolean
    selectMarket: string
    selectBusiness: string
  }
  loadMissingBroadcastModal: any
}

const ReportingFulfillmentTable: React.FC<ReportingFulfillmentTableProps> = ({ data, filters, loadMissingBroadcastModal }) => {
  const renderHtml = useCallback((cell: any) => {
    return <div>{parse(cell.value)}</div>
  }, [])

  const renderButtons = useCallback((row: any) => {
    const jobId = row['jobId']
    return <a href={'#' + jobId} onClick={() => { loadMissingBroadcastModal(jobId) }}>{row['buttonsText']}</a>

  }, [loadMissingBroadcastModal])

  const sortButtons = useMemo(() => (rowA: any, rowB: any, columnId: string, desc: boolean) => {
    const diff = rowA.values['missingHelperCount'] - rowB.values['missingHelperCount']
    return diff > 0 ? 1 : (diff < 0 ? -1 : 0)
  }, [])

  const columns = useMemo(() => {
    return [
      { Header: 'Start/Duration', accessor: 'dateTimeText', Cell: renderHtml },
      { Header: 'Job#', accessor: 'jobNumberText', Cell: renderHtml },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Market', accessor: 'marketName' },
      { Header: 'Use Case', accessor: 'useCaseType' },
      { Header: 'Needs', accessor: 'helperNeedsText', Cell: renderHtml },
      { Header: 'Broadcast (Past)', accessor: 'bidWindowsBroadcastedText', Cell: renderHtml },
      { Header: 'Broadcast (Current)', accessor: 'bidWindowsBroadcastText', Cell: renderHtml },
      { Header: 'Broadcast (Visible)', accessor: 'bidWindowsBroadcastVisibleText', Cell: renderHtml },
      { Header: '', accessor: renderButtons, id: 'buttonColumn', sortType: sortButtons },
      { Header: '', accessor: 'business' },
      { Header: '', accessor: 'missingHelperCount' },
    ]
  }, [renderHtml, renderButtons, sortButtons])

  const hiddenColumns = useMemo(() => {
    return ['business', 'missingHelperCount']
  }, [])
  const sortBy = useMemo(() => {
    return [{ id: 'dateTimeText' }]
  }, [])

  const initialState = { hiddenColumns, sortBy }

  // useTable "as any" since typescript for react-table is out-of-whack right now
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleHideColumn,
    setAllFilters,
  } = useTable({ columns, data, initialState }, useFilters, useSortBy) as any

  const {
    filterPastBroadcasts,
    selectMarket,
    selectBusiness,
  } = filters

  //, useFilters, useGlobalFilter
  useEffect(() => {
    toggleHideColumn('bidWindowsBroadcastedText', !filterPastBroadcasts)
    // toggleHideColumn('business', true)
  }, [data, toggleHideColumn, filterPastBroadcasts])

  useEffect(() => {
    const filters: { id: string, value: string }[] = []
    if (selectMarket) {
      filters.push({ id: 'marketName', value: selectMarket })
    }
    if (selectBusiness) {
      filters.push({ id: 'business', value: selectBusiness })
    }
    setAllFilters(filters)
  }, [data, setAllFilters, selectMarket, selectBusiness])

  return (
    <Styles>
      <div className="table-container">
        <table {...getTableProps()} className="table is-narrow is-hoverable is-bordered is-fullwidth tableWrap">
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row<object>) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="content is-normal" style={{ maxWidth: "80em", margin: "auto" }}>
        <h1>How this works</h1>
        <p>A Dolly broadcast includes 4 potential states for a given helper:</p>
        <ol>
          <li>Helper has never had a bid window generated. (Not Broadcast past, current, or visible)</li>
          <li>Helper has had a bid window generated, but none currently exist. (Had Broadcast past, but not current or visible.)</li>
          <li>Helper currently has a bid window, but its location_matches is false. Helper would see the Dolly if looking at the map view of the Helper App. (Has Broadcast past and current, but not visible.)</li>
          <li>Helper currently has a bid window and location_matches is true. Helper would see the Dolly in both map view and the Open Dollys list of the Helper App. (Has Broadcast past, current, and visible.)</li>
        </ol>
        <h2>Visibility Percentages:</h2>
        <p>The numbers in the broadcast column indicate the % of the jobs viewable in the app by the following designations: all, teams, solo Helpers, Hands, Tiers, and supply by Vehicle type.</p>
        <h2>Missing Broadcasts:</h2>
        <p>The count is generated by subtracting those helpers that currently see it in the Open Dollys list from the number of helpers in the market/megamarket. Then the modal shows those helpers with information on their broadcast status. So if the count of helpers in a megamarket is 1000, and the * percentage for all helpers in the Broadcast (Visible) column is 30%, then it should show a count of roughly 700 helpers. They would not see the Dolly in their Open Dollys list and we would want to know why.</p>
        <p>&nbsp;</p>
        <p>Click <strong>Load Data</strong> above for initial data load.</p>
      </div>
    </Styles>
  )
}

export default ReportingFulfillmentTable;