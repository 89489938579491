import { grpc } from '@improbable-eng/grpc-web'
import { DOLLY_PLATFORM_URL } from '../../types/constants'
import { ServiceAvailabilityConfigurationService } from '../../gen/mondo/v1/service_availability_configuration_pb_service'
import { GetServiceAvailabilityConfigurationRequest, GetServiceAvailabilityConfigurationResponse } from '../../gen/mondo/v1/service_availability_configuration_pb'
import { ServiceAvailabilityConfiguration } from '../../gen/mondo/v1/common_pb'

const getServiceAvailabilityConfiguration = async (metadata: grpc.Metadata, id: string) => {
  return new Promise<ServiceAvailabilityConfiguration>((resolve, reject) => {
    const getServiceAvailabilityConfigurationRequest = new GetServiceAvailabilityConfigurationRequest()
    getServiceAvailabilityConfigurationRequest.setId(id)
    grpc.unary(ServiceAvailabilityConfigurationService.GetServiceAvailabilityConfiguration, {
      host: DOLLY_PLATFORM_URL,
      request: getServiceAvailabilityConfigurationRequest,
      metadata: metadata,
      onEnd: res => {
        const { status, message, statusMessage } = res
        if (status === grpc.Code.OK && message) {
          const getServiceAvailabilityConfigurationResponse = message as GetServiceAvailabilityConfigurationResponse;
          const configuration = getServiceAvailabilityConfigurationResponse.getConfiguration()
          if (configuration) {
            resolve(configuration)
          } else {
            reject("No Configuration returned")
          }
        } else {
          reject(statusMessage.toString())
        }
      }
    })
  })
}

export { getServiceAvailabilityConfiguration }
