import { grpc } from '@improbable-eng/grpc-web'
import { validate } from 'uuid'
import { DOLLY_PLATFORM_URL } from '../../types/constants'
import { ServiceAvailabilityConfigurationService } from '../../gen/mondo/v1/service_availability_configuration_pb_service'
import {
  CreateServiceAvailabilityConfigurationRequest,
  CreateServiceAvailabilityConfigurationResponse,
  UpdateServiceAvailabilityConfigurationRequest
} from '../../gen/mondo/v1/service_availability_configuration_pb'
import { ServiceAvailabilityConfiguration } from '../../gen/mondo/v1/common_pb'

const saveServiceAvailabilityConfiguration = async (metadata: grpc.Metadata, configuration: ServiceAvailabilityConfiguration) => {
  return new Promise<string>((resolve, reject) => {
    if (configuration.getScope() === 'new-scope') {
      reject("scope should be changed from default")
      return
    }

    if (configuration.getId() === 'new') {
      const req = new CreateServiceAvailabilityConfigurationRequest()
      req.setScope(configuration.getScope())
      req.setLabel(configuration.getLabel())
      req.setActive(configuration.getActive())
      req.setOverrideScope(configuration.getOverrideScope())
      req.setWeaverCustomerEntityId(configuration.getWeaverCustomerEntityId())
      req.setWeaverLaborEntityId(configuration.getWeaverLaborEntityId())
      req.setOutsideServiceAreaDeliverableRange(configuration.getOutsideServiceAreaDeliverableRange())
      req.setLongRangeDeliveryLimit(configuration.getLongRangeDeliveryLimit())
      configuration.getTaskMarketSurgeLimitsMap().toArray().forEach(d => req.getTaskMarketSurgeLimitsMap().set(d[0], d[1]))
      grpc.unary(ServiceAvailabilityConfigurationService.CreateServiceAvailabilityConfiguration, {
        host: DOLLY_PLATFORM_URL,
        request: req,
        metadata: metadata,
        onEnd: res => {
          const { status, message, statusMessage } = res
          if (status === grpc.Code.OK && message) {
            const getServiceAvailabilityConfigurationResponse = message as CreateServiceAvailabilityConfigurationResponse;
            const id = getServiceAvailabilityConfigurationResponse.getId()
            if (id) {
              resolve(id)
            } else {
              reject("No id returned")
            }
          } else {
            reject(statusMessage.toString())
          }
        }
      })
      return
    }

    if (!validate(configuration.getId())) {
      reject("Unexpected Configuration Id")
      return
    }

    const req = new UpdateServiceAvailabilityConfigurationRequest()
    req.setId(configuration.getId())
    req.setScope(configuration.getScope())
    req.setLabel(configuration.getLabel())
    req.setActive(configuration.getActive())
    req.setOverrideScope(configuration.getOverrideScope())
    req.setWeaverCustomerEntityId(configuration.getWeaverCustomerEntityId())
    req.setWeaverLaborEntityId(configuration.getWeaverLaborEntityId())
    req.setOutsideServiceAreaDeliverableRange(configuration.getOutsideServiceAreaDeliverableRange())
    req.setLongRangeDeliveryLimit(configuration.getLongRangeDeliveryLimit())
    configuration.getTaskMarketSurgeLimitsMap().toArray().forEach(d => req.getTaskMarketSurgeLimitsMap().set(d[0], d[1]))
    grpc.unary(ServiceAvailabilityConfigurationService.UpdateServiceAvailabilityConfiguration, {
      host: DOLLY_PLATFORM_URL,
      request: req,
      metadata: metadata,
      onEnd: res => {
        const { status, message, statusMessage } = res
        if (status === grpc.Code.OK && message) {
          resolve(configuration.getId())
        } else {
          reject(statusMessage.toString())
        }
      }
    })
  })
}

export { saveServiceAvailabilityConfiguration }
