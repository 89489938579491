import { DollyApiTokenGoogle } from './get-dolly-api-token-google'
import { fetchPost } from './fetchPost'

interface CuratedHelperData {
  first_name?: string,
  last_name?: string,
  email?: string,
  roles: {
    helper: {
      market_name: string
      tier: string
      helper_count: number
      vehicle: number
      vehicles?: {
        type: string,
        approved?: number,
        deleted?: number
      }[]
    }
  }
}
const CURATED_HELPER_UNKNOWN: CuratedHelperData = {
  first_name: 'Unknown',
  last_name: 'Helper',
  email: 'no_email',
  roles: {
    helper: {
      market_name: 'unknown_market',
      tier: 'unknown_tier',
      helper_count: 1,
      vehicle: 0,
    }
  }
}

interface CuratedHelpersById {
  [key: string]: CuratedHelperData
}

interface findHelpersCuratedQuery {
  retrieveContactInfo: boolean
}
async function findHelpersCurated(dollyApiTokenGoogle: DollyApiTokenGoogle, query: findHelpersCuratedQuery) {
  const { apitoken, googleId } = dollyApiTokenGoogle
  const body = JSON.stringify({
    ...query,
    apitoken,
    googleId
  })
  const results = await fetchPost('/v2/job/reporting/fulfillment/findhelperscurated', body)
  return results as { helpersById: CuratedHelpersById }
}

export type {
  CuratedHelperData,
  CuratedHelpersById,
  findHelpersCuratedQuery
}
export { findHelpersCurated, CURATED_HELPER_UNKNOWN }